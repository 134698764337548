import style from "./Footer.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
        <footer className={style.footer}>

            <div className={style.figure}>
                {svgIcons.backgroundFigure_FixToken}
            </div>

            <footer className={style.inner}>

                <div className={style.top}>
                    {svgIcons.logo}

                    <div className={style.socialLinks}>
                        {
                            [
                                {
                                    href: "#",
                                    icon: svgIcons.snapchat
                                },
                                {
                                    href: "#",
                                    icon: svgIcons.youtube
                                },
                                {
                                    href: "#",
                                    icon: svgIcons.facebook
                                },
                            ].map(({href, icon}, key) => (
                                <a key={key}
                                   href={href}
                                   target="_blank"
                                   rel="noreferrer noopener nofollow"
                                >
                                    {icon}
                                </a>
                            ))
                        }
                    </div>
                </div>

                <div className={style.bottom}>

                    <div className={style.links}>
                        {
                            [
                                {
                                    label: "FAQ’s",
                                    to: "#",
                                },
                                {
                                    label: "Press and Media",
                                    to: "#",
                                },
                                {
                                    label: "Security and Compliance",
                                    to: "#",
                                },
                                {
                                    label: "Legal Disclaimers",
                                    to: "#",
                                },
                                {
                                    label: "Presale Calculator",
                                    to: "#",
                                },
                                {
                                    label: "Prop Challenges",
                                    to: "#",
                                },
                                {
                                    label: "Press Kit",
                                    to: "#",
                                },
                                {
                                    label: "Our Vision",
                                    to: "#",
                                },
                                {
                                    label: "News Articles",
                                    to: "#",
                                },
                                {
                                    label: "Private Client Contact Us",
                                    to: "#",
                                },
                            ].map(({label, to}, key) => (
                                <Link key={key}
                                      to={to}
                                      className={style.link}
                                >
                                    {label}
                                </Link>
                            ))
                        }
                    </div>

                    <div className={style.secondLinks}>
                        <p>
                            © 2024 FundFix
                        </p>
                        <Link to="#">
                            Privacy Policy
                        </Link>
                    </div>
                </div>

            </footer>
        </footer>
    )
}

