import {SliderValueLabelProps} from "@mui/material/Slider/Slider";
import Tooltip, {TooltipProps} from "@mui/material/Tooltip";
import style from "./ValueLabelComponent.module.scss"
import {ButtonCustom, VariantEnum} from "../../_common/ButtonCustom/ButtonCustom";
import {styled, tooltipClasses} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../store/rootStore";
import {useState} from "react";

export const ValueLabelComponent = observer((props: SliderValueLabelProps) => {
    const {children, value} = props;
    const {appStore: {setValue}} = useStore()
    const [open, setOpen] = useState(false)

    return (
        <HtmlTooltip enterTouchDelay={0}
                     placement="bottom"
                     arrow={true}
                     //open={open}
                     sx={{
                         padding: 0,
                         "& .MuiTooltip-tooltip": {
                             padding: 0,
                         },
                     }}
                     title={
                         <div className={style.valueLabelComponent}>
                             <div className={style.infos}>
                                 {
                                     [
                                         {
                                             label: "Stage",
                                             value: "Private Sale",
                                         },
                                         {
                                             label: "Price",
                                             value: `$${value}`,
                                         },
                                     ].map(({label, value}, key) => (
                                         <div key={key}>
                                             <p>{label}</p>
                                             <p>{value}</p>
                                         </div>
                                     ))
                                 }
                             </div>

                             <ButtonCustom label="Reset"
                                           variant={VariantEnum.contained}
                                           className={style.btn}
                                           onClick={() => setValue(0)}
                             />
                         </div>
                     }
        >
            {children}
        </HtmlTooltip>


    )
})

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: 0,
        borderRadius: 24,
        // width: "209px",
        // height: "130px",
        // backgroundColor: '#f5f5f9',
        // color: 'rgba(0, 0, 0, 0.87)',
        // maxWidth: 220,
        // fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
    },
}));