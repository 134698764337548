import style from "./EarnByBringing.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import src from "../../assets/png/EarnByBringing/wave.png";
import {useRef} from "react";
import {useGSAP} from "@gsap/react";
import {clsx} from "clsx";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const EarnByBringing = () => {
    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {

        const mm = gsap.matchMedia();

        mm.add("(min-width: 1440px)", (context) => {

            gsap.to(".inner", {
                scrollTrigger: {
                    trigger: ".inner",
                    //markers: true,
                    start: "center center",
                    end: "bottom top-=1000px",
                    pin: ".earnByBringing",
                }
            })

            gsap.to(".left", {
                width: "100%", ease: "none",
                scrollTrigger: {
                    trigger: ".inner",
                    //markers: true,
                    start: "center center-=400px",
                    end: "bottom top-=1400px",
                    scrub: true,
                }
            })

            gsap.to(".right", {
                width: "0%", ease: "none",
                scrollTrigger: {
                    trigger: ".inner",
                    //markers: true,
                    start: "center center",
                    end: "bottom top-=1000px",
                    scrub: true,
                }
            })

        }, appRef)

    }, {scope: appRef})


    return (
        <div ref={appRef}>
            <div className={clsx(style.earnByBringing, "earnByBringing")}

            >
                <div className={clsx(style.inner, "inner")}>

                    <h2 className={style.title}>
                        <p>
                            Earn by {svgIcons.moneyBag} bringing
                        </p>
                        <p>
                            Traders to FundFix
                        </p>
                    </h2>

                    <p className={style.description}>
                        Join our affiliate program and earn commissions for each new trader who participates in the
                        presale.
                        Share your unique link and watch your rewards grow. Our multi-level program offers generous
                        commissions and multiple ways to earn
                    </p>

                    <ButtonCustom label="Join the Affiliate Program"
                                  variant={VariantEnum.outlined}
                                  className={style.btn}
                    />

                    <p className={style.cardsTitle}>
                        Affiliate Benefits
                    </p>

                    <div className={clsx(style.cards, "cards")}>
                        {
                            [
                                {
                                    cardTitle: <p>Up to <span>20%</span> Commissions</p>,
                                    text: <p>// earn a <span>PERCENTAGE</span> of every challenge <span>FEE</span> sold
                                    </p>,
                                },
                                {
                                    cardTitle: <p>Referral Discounts</p>,
                                    text: <p>// <span>YOUR NETWORK</span> network gets a 10% <span>DISCOUNT</span> on
                                        challenges</p>,
                                },
                                {
                                    cardTitle: <p>Marketing Resources</p>,
                                    text: <p>// access <span>BANNERS</span>, <span>LINKS</span>, and
                                        promotional <span>materials</span></p>,

                                },
                                {
                                    cardTitle: <p>Real-Time Tracking</p>,
                                    text: <p>// monitor your <span>REFERRALS</span> and <span>EARNINGS</span> through
                                        your <span>DASHBOARD</span></p>,
                                },
                            ].map(({cardTitle, text}, key) => (
                                <div key={key}
                                     className={style.card}
                                >
                                    {cardTitle}
                                    {text}
                                    {key === 0 && <img src={src} alt=""/>}
                                </div>
                            ))
                        }


                        <div className={style.waveWrapper}>
                            <div className={clsx(style.left, "left")}>
                                {svgIcons.waveBack}
                                {svgIcons.waveDashed}
                            </div>
                            <div className={clsx(style.center)}>
                                {svgIcons.waveYellow}
                            </div>
                            <div className={clsx(style.right, "right")}>
                                {svgIcons.waveBack}
                                {svgIcons.waveDashed}
                            </div>
                        </div>
                        {/*<img src={src} alt=""/>*/}
                    </div>

                </div>
            </div>
        </div>

    )
}