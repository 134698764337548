export const data = [
    {
        price: "$3.57",
        amount: "+11,900 $FIX",
        status: "Pending"
    },
    {
        price: "$3.57",
        amount: "+11,900 $FIX",
        status: "Completed"
    },
    {
        price: "$3.57",
        amount: "+11,900 $FIX",
        status: "Completed"
    },
    {
        price: "$3.57",
        amount: "+11,900 $FIX",
        status: "Pending"
    },
    {
        price: "$3.57",
        amount: "+11,900 $FIX",
        status: "Completed"
    },
    {
        price: "$3.57",
        amount: "+11,900 $FIX",
        status: "Completed"
    },
]