export const data = [
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
    {
        address: "0x098123456781680",
        deposit: "$800",
        tokens: "500 $FIX",
    },
]