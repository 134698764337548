import style from "./GetStartedWithMetaMask.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
// @ts-ignore
import video0 from "../../assets/webm/getStarted0.webm";
// @ts-ignore
import video1 from "../../assets/webm/getStarted1.webm";
// @ts-ignore
import video2 from "../../assets/webm/getStarted2.webm";
import {clsx} from "clsx";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import {useRef} from "react";
import {MotionPathPlugin} from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

export const GetStartedWithMetaMask = () => {

    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {

        const mm = gsap.matchMedia();

        const cardsCount = 3;
        const paddingTop = 20;
        const cardHeight = 350;
        const cardsGap = 20;
        const cardHeader = 20 + 25 + 20;

        mm.add("(max-width: 1439px)", (context) => {

            Array.from(({length: cardsCount}), (v, k) => k)
                .forEach(key => {
                    gsap.to(`.card${key}`, {
                        scrollTrigger: {
                            trigger: `.card${cardsCount - 1}`,
                            //markers: true,
                            start: `top top+=${paddingTop + key * cardHeader + (cardsCount - (key + 1)) * (cardHeight + cardsGap)}px`,
                            end: `top top+=${paddingTop + (cardsCount - 1) * cardHeader}px`,
                            pin: `.card${key}`,
                            pinSpacing: false,
                        }
                    })
                })

        }, appRef)

    }, {scope: appRef})

    return (
        <div className={style.getStartedWithMetaMask}
             ref={appRef}
        >
            <div className={style.inner}>

                <h2 className={style.title}>
                    <p>
                        Get started with
                    </p>
                    <p>
                        {svgIcons.metamask} MetaMask
                    </p>
                </h2>

                <p className={style.description}>
                    To participate in the FundFix presale, you need an Ethereum-compatible wallet like MetaMask. Here’s
                    a quick guide to get set up
                </p>

                <ButtonCustom label="Set Up Your Wallet Now"
                              variant={VariantEnum.outlined}
                              className={style.setUpBtn}
                />

                <div className={style.cards}>
                    {
                        [
                            {
                                title: "Download MetaMask",
                                text: "Install the MetaMask extension from the official website or app store",
                                src: video0,
                            },
                            {
                                title: "Create a New Wallet",
                                text: "Follow the on-screen prompts to create your wallet, and securely save your seed phrase",
                                src: video1,
                            },
                            {
                                title: "Fund Your Wallet",
                                text: "Transfer ETH, BNB, or USDT to your MetaMask wallet",
                                src: video2,
                            },
                        ].map(({title, text, src}, key) => (
                            <div key={key}
                                 className={clsx(style.card, `card${key}`)}
                                 style={{zIndex: key + 1}}
                            >
                                <div className={style.cardHeader}>
                                    <p>{title}</p>
                                    <p>/ 0{key + 1} /</p>
                                </div>

                                <div className={style.videoWrapper}>
                                    <video src={src}
                                           playsInline={true}
                                           loop={true}
                                           muted={true}
                                           autoPlay={true}
                                    />
                                </div>


                                <p>{text}</p>
                            </div>
                        ))
                    }

                </div>

            </div>
        </div>
    )
}