import style from "./FixToken.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import {Lines} from "../_common/Lines/Lines";

export const FixToken = () => {
    return (
        <div className={style.fixToken}>

            <div className={style.linesWrapper}>
                <Lines count={4}/>
            </div>

            <div className={style.figure}>
                {svgIcons.backgroundFigure_FixToken}
            </div>


            <div className={style.inner}>

                <h2 className={style.title}>
                    <p>
                        $FIX {svgIcons.graph} Token:
                    </p>
                    <p>
                        Driving the FundFix
                    </p>
                    <p>
                        Ecosystem
                    </p>
                </h2>

                <p className={style.descriptionMobile}>
                    The <span>$FIX token</span> is more than a utility—it’s what <span>connects traders</span> to our
                    platform’s core benefits,
                    including access to <span>premium features</span> and revenue-sharing <span>options</span>.
                </p>

                <ButtonCustom label="View Full Tokenomics"
                              variant={VariantEnum.outlined}
                              className={style.viewBtn}
                />

                <div className={style.tokenDistribution}>

                    <div className={style.top}>
                        <p className={style.label}>
                            Token Distribution
                        </p>
                        <p className={style.descriptionDesktop}>
                            The <span>$FIX token</span> is more than a utility—it’s
                            what <span>connects traders</span> to our platform’s core benefits,
                            including access to <span>premium features</span> and revenue-sharing <span>options</span>.
                        </p>
                    </div>

                    <div className={style.bottom}>

                        <div className={style.line}>
                            {
                                [
                                    {
                                        background: "#f7fa5a",
                                        value: 50,
                                        width: 46,
                                        gradient: "linear-gradient(180deg, rgba(247, 250, 90, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)",
                                        label: "Presale",
                                    },
                                    {
                                        background: "#6a5afa",
                                        value: 25,
                                        width: 27,
                                        gradient: "linear-gradient(180deg, rgba(106, 90, 250, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)",
                                        label: "Liquidity",
                                    },
                                    {
                                        background: "#fa5a5a",
                                        value: 25,
                                        width: 27,
                                        gradient: "linear-gradient(180deg, rgba(250, 90, 90, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)",
                                        label: "PLP",
                                    }
                                ].map(({background, value, gradient, label, width}, key) => (
                                    <div key={key}
                                         className={style.lineItem}
                                         style={{
                                             background,
                                             width: `${width}%`
                                         }}
                                    >
                                        <p style={{color: background}}
                                           className={style.value}
                                        >
                                            {value}%
                                        </p>
                                        <div style={{background: gradient}}/>
                                        <p className={style.label}>
                                            {label}: <span style={{color: background}}>{value}%</span>
                                        </p>
                                    </div>
                                ))
                            }
                        </div>

                        <div className={style.info}>

                            <div className={style.left}>

                                <p className={style.infoLabel}>
                                    Primary
                                </p>

                                <div className={style.leftItems}>
                                    {
                                        [
                                            {
                                                background: "#6a5afa",
                                                label: "Liquidity",
                                            },
                                            {
                                                background: "#fa5a5a",
                                                label: "PLP",
                                            },
                                            {
                                                background: "#f7fa5a",
                                                label: "Presale",
                                            },
                                        ].map(({background, label}, key) => (
                                            <div key={key}
                                                 className={style.item}
                                            >
                                                <div style={{background}}/>
                                                <p>{label}</p>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                            <div className={style.right}>

                                <p className={style.infoLabel}>
                                    Other
                                </p>

                                <div className={style.rightItems}>
                                    {
                                        [
                                            {
                                                label: "Marketing & Growth:",
                                                value: <p>10%</p>,
                                            },
                                            {
                                                label: "Development:",
                                                value: <p>5% <span>(vested)</span></p>,
                                            },
                                            {
                                                label: "Team:",
                                                value: <p>5% <span>(vested over 3 years)</span></p>,
                                            },
                                        ].map(({label, value}, key) => (
                                            <div key={key}
                                                 className={style.item}
                                            >
                                                <p>{label}</p>
                                                {value}
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}