import style from "./BurgerMenu.module.scss"
import {observer} from "mobx-react-lite";
import {useStore} from "../../store/rootStore";
import {clsx} from "clsx";
import {Link} from "react-router-dom";

export const BurgerMenu = observer(() => {
    const {
        appStore: {
            burgerMenu, setBurgerMenu
        }
    } = useStore();


    return (
        <div className={clsx({
            [style.burgerMenu]: true,
            [style.burgerMenu_burgerMenu]: burgerMenu,
        })}>

            <div className={style.links}>
                {
                    [
                        {
                            label: "Presale Overview",
                            to: "#",
                        },
                        {
                            label: "How to Buy",
                            to: "#",
                        },
                        {
                            label: "Set Up Your Wallet",
                            to: "#",
                        },
                        {
                            label: "Leaderboard",
                            to: "#",
                        },
                        {
                            label: "Presale Calculator",
                            to: "#",
                        },
                        {
                            label: "Affiliate Program",
                            to: "#",
                        },
                        {
                            label: "Presale Dashboard",
                            to: "#",
                        },
                    ].map(({label, to}, key) => (
                        <Link key={key}
                              to={to}
                              className={style.link}
                              onClick={() => setBurgerMenu(false)}
                        >
                            <p>{label}</p>
                            <p>/ 0{key + 1} / </p>
                        </Link>
                    ))
                }
            </div>

        </div>
    )
})