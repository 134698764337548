import style from "./WhoWeAre.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import {Lines} from "../_common/Lines/Lines";

export const WhoWeAre = () => {
    return (
        <div className={style.whoWeAre}>

            <div className={style.inner}>

                <div className={style.linesWrapper}>
                    <Lines count={4}/>
                </div>

                <div className={style.figure}>
                    {svgIcons.backgroundFigure_WhoWeAre}
                </div>

                <h2 className={style.title}>
                    Who We {svgIcons.user} Are
                </h2>

                <p className={style.description}>
                    FundFix is a trading platform designed to back traders with firm capital. Our mission is to provide
                    access to fully funded accounts for traders looking to operate at a higher level. We combine
                    traditional and decentralized finance under one roof.
                </p>

                {/*<ButtonCustom label="Explore all Features"*/}
                {/*              variant={VariantEnum.outlined}*/}
                {/*              className={style.exploreBtn}*/}
                {/*/>*/}

                <div className={style.cards}>
                    {
                        [
                            {
                                cardTitle: "The team",
                                label: "// Composed of experts from leading",
                                text: <p><span>TRADING FIRMS, PRIME BROKERAGES, HEDGE FUNDS, BYBIT DEVELOPERS</span>
                                </p>,
                            },
                            {
                                cardTitle: "WE HAVE",
                                label: "// combined extensive",
                                text: <p><span>MARKET EXPERIENCE</span>, with <span>CUTTING-EDGE TECHNOLOGY</span>,
                                    to build <span>A PLATFORM</span>, that delivers <span>POWERFUL RESULTS</span></p>,
                            },
                            {
                                cardTitle: "OUR STORY",
                                label: "// we’re a trading platform",
                                text: <p>designed to <span>BACK TRADERS</span> with <span>Firm capital</span>
                                </p>,

                            },
                            {
                                cardTitle: "OUR MISSIOn",
                                label: "// to provide access to",
                                text: <p><span>fully funded</span> accounts
                                    for <span>traders</span> looking to <span>OPERATE</span> at
                                    a <span>HIGHER LEVEL</span></p>,
                            },
                        ].map(({cardTitle, label, text}, key) => (
                            <div key={key}
                                 className={style.card}
                            >
                                <p>{cardTitle}</p>
                                <div>
                                    <p>{label}</p>
                                    {text}
                                </div>

                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}