export const slides = [
    {
        label: "Q1 2024",
        items: [
            "Affiliate Program Release",
            "Global Marketing Campaigns",
            "Mobile App Launch",
            "Full Academy Release",
            "OnChain Trading",
            "Exchange Listings",
            "FundFix Dashboard Launch",
            "Academy Beta",
        ]
    },
    {
        label: "Q2 2024",
        items: [
            "Affiliate Program Release",
            "Global Marketing Campaigns",
            "Mobile App Launch",
        ]
    },
    {
        label: "Q3 2024",
        items: [
            "Affiliate Program Release",
            "Global Marketing Campaigns",
        ]
    },
    {
        label: "Q4 2024",
        items: [
            "FundFix Dashboard Launch",
            "Academy Beta",
        ]
    },

]