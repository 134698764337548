import style from "./Stake.module.scss"
import {svgIcons} from "../../../../assets/svgIcons";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {TextField} from "../../../_common/TextField/TextField";
import {ButtonCustom} from "../../../_common/ButtonCustom/ButtonCustom";

interface IValues {
    amount: string
}

const initialValues: IValues = {
    amount: "",
}


export const Stake = () => {

    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
        try {
            console.log(values);

        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    return (
        <div className={style.stake}>

            <p className={style.cardTitle}>
                Stake
            </p>

            <p className={style.subTitle}>
                Up to 80% APY
            </p>

            <div className={style.section}>
                {
                    [
                        {
                            text1: "778,666.66 $FIX",
                            text2: "Staked",
                        },
                        {
                            text1: "778,666.66 $FIX",
                            text2: "Daily Staking",
                        },
                        {
                            text1: "43,732.86 $FIX",
                            text2: "Total Earnings",
                        },
                    ].map(({text1, text2}, key) => (
                        <div key={key}
                             className={style.sectionItem}
                        >
                            <p>{text1}</p>
                            <p>{text2}</p>
                        </div>
                    ))
                }
            </div>

            <div className={style.available}>
                {svgIcons.infoCircle}
                <p>
                    Available to Stake: <span>742,392 $FIX</span>
                </p>
            </div>

            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {
                    (props: FormikProps<IValues>) => (
                        <Form autoComplete="off"
                              className={style.form}
                        >


                            <TextField label="Enter amount"
                                       name="amount"
                            />

                            <div className={style.btns}>
                                <ButtonCustom label="Stake"
                                              type="submit"
                                              className={style.btn}
                                />
                                <ButtonCustom label="Unskate"
                                              type="submit"
                                              className={style.btn}
                                />
                            </div>


                        </Form>
                    )
                }
            </Formik>


        </div>
    )
}