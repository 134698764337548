import style from "./ButtonCustom.module.scss"
import {ButtonHTMLAttributes, DetailedHTMLProps, FC, useState} from "react";
import {clsx} from "clsx";

export enum VariantEnum {
    contained = "contained",
    outlined = "outlined"
}


type IButtonCustom = {
    label: string
    variant?: VariantEnum
    className?: string
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const ButtonCustom: FC<IButtonCustom> = ({
                                                    label,
                                                    variant = VariantEnum.contained,
                                                    className,
                                                    ...buttonProps
                                                }) => {
    const [mouseDown, setMouseDown] = useState(false);
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);

    return (
        <button className={clsx({
            [style.buttonCustom]: true,
            [style.buttonCustom_contained]: variant === VariantEnum.contained,
            [style.buttonCustom_contained_mouseDown]: variant === VariantEnum.contained && mouseDown,
            [style.buttonCustom_outlined]: variant === VariantEnum.outlined,
            [style.buttonCustom_outlined_mouseDown]: variant === VariantEnum.outlined && mouseDown,
        }, Boolean(className) && className)}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                {...buttonProps}

        >
            <span>{label}</span>
        </button>
    )
}