import {action, makeObservable, observable} from "mobx";

export class AppStore {
    burgerMenu = false
    value = 30

    constructor() {
        makeObservable(this,
            {
                burgerMenu: observable,
                value: observable,

                setBurgerMenu: action.bound,
                setValue: action.bound,

            }
        )
    }

    setBurgerMenu(burgerMenu: boolean) {
        this.burgerMenu = burgerMenu;
    }

    setValue(value: number) {
        this.value = value
    }


}