import style from "./Header.module.scss"
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import {Link} from "react-router-dom";
import {svgIcons} from "../../assets/svgIcons";
import {observer} from "mobx-react-lite";
import {useStore} from "../../store/rootStore";
import {clsx} from "clsx";

export const Header = observer(() => {
    const {
        appStore: {
            burgerMenu, setBurgerMenu
        }
    } = useStore();


    return (
        <header className={style.header}>

            <Link to="/" className={style.logo}>
                {svgIcons.logo}
            </Link>

            <div className={style.right}>
                <ButtonCustom label="Sign In"
                              variant={VariantEnum.outlined}
                              className={style.signInBtn}
                />
                <ButtonCustom label="Join presale"
                              className={style.joinPresaleBtn}
                />
                <button className={clsx({
                    [style.burgerBtn]: true,
                    [style.burgerBtn_burgerMenu]: burgerMenu,
                })}
                        onClick={() => setBurgerMenu(!burgerMenu)}
                >
                    {svgIcons.burger}
                </button>
            </div>


        </header>
    )
})