import {JoinTheFix} from "../C2_JoinTheFix/JoinTheFix";
import {PresaleStages} from "../C3_PresaleStages/PresaleStages";
import {BuyTokens} from "../C4_BuyTokens/BuyTokens";
import {GetStartedWithMetaMask} from "../C5_GetStartedWithMetaMask/GetStartedWithMetaMask";
import {TopContributors} from "../C6_TopContributors/TopContributors";
import {EstimateYourInvestment} from "../C7_EstimateYourInvestment/EstimateYourInvestment";
import {EarnByBringing} from "../C8_EarnByBringing/EarnByBringing";
import {YourPresaleDashboard} from "../C9_YourPresaleDashboard/YourPresaleDashboard";
import {FairDistribution} from "../C10_FairDistribution/FairDistribution";

export const PresalePage = () => {
    return (
        <>
            <JoinTheFix/>
            <PresaleStages/>
            <BuyTokens/>
            <GetStartedWithMetaMask/>
            <TopContributors/>
            <EstimateYourInvestment/>
            <EarnByBringing/>
            <YourPresaleDashboard/>
            <FairDistribution/>
        </>
    )
}