import style from "./EstimateYourInvestment.module.scss"
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import {svgIcons} from "../../assets/svgIcons";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {TextField} from "../_common/TextField/TextField";
import Slider, {SliderThumb} from '@mui/material/Slider';
import {HTMLAttributes, useState} from "react";
import {ThumbComponent} from "./ThumbComponent/ThumbComponent";
import {ValueLabelComponent} from "./ValueLabelComponent/ValueLabelComponent";
import {observer} from "mobx-react-lite";
import {useStore} from "../../store/rootStore";

interface IValues {
    value: string
    amount: string
}

const initialValues: IValues = {
    value: "",
    amount: "",
}

export const EstimateYourInvestment = observer(() => {
    const {appStore: {value, setValue}} = useStore()
    //const [value, setValue] = useState<number>(30);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };


    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
        try {
            console.log(values);

        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    return (
        <div className={style.estimateYourInvestment}>
            <div className={style.inner}>

                <h2 className={style.title}>
                    Estimate Your Investment
                </h2>

                <p className={style.text}>
                    Use our presale calculator to see how many $FIX tokens you’ll receive based on your investment.
                    Input your amount and view potential returns
                </p>

                <ButtonCustom label="Try the Presale Calculator"
                              variant={VariantEnum.outlined}
                              className={style.btn}
                />

                <div className={style.content}>

                    <div className={style.top}>
                        <div className={style.card}>
                            <p className={style.cardHeader}>
                                Calculate your profit
                            </p>
                            <p className={style.cardDescription}>
                                Enter how much BDAG coins you have in the input below
                            </p>

                            <Formik initialValues={initialValues}
                                    onSubmit={onSubmit}
                            >
                                {
                                    (props: FormikProps<IValues>) => (
                                        <Form autoComplete="off"
                                              className={style.form}
                                        >


                                            <TextField label="Enter"
                                                       name="value"
                                            />

                                            <TextField label="USD Amount"
                                                       name="amount"
                                                       className={style.amount}
                                            />

                                        </Form>
                                    )
                                }
                            </Formik>

                            <div className={style.info}>
                                {
                                    [
                                        {
                                            label: "Stage",
                                            value: "Private Sale",
                                        },
                                        {
                                            label: "Bonus",
                                            value: "20%",
                                        },
                                        {
                                            label: "Current Price",
                                            value: "$0.0020",
                                        },

                                    ].map(({label, value}, key) => (
                                        <div key={key}>
                                            <p>{label}</p>
                                            <p>{value}</p>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className={style.sliderWrapper}>
                                <Slider value={value}
                                        valueLabelDisplay="on"
                                        onChange={handleChange}
                                        slots={{
                                            thumb: ThumbComponent,
                                            valueLabel: ValueLabelComponent
                                }}
                                        sx={{
                                            padding: "0px 0px!important",
                                            height: "28px",
                                            borderRadius: "14px",
                                            "& .MuiSlider-rail": {
                                                backgroundColor: "#313332",
                                                opacity: 1,
                                            },
                                            "& .MuiSlider-track": {
                                                backgroundColor: "#f7fa5a",
                                                border: "none",
                                                borderRadius: 0,
                                            },
                                            "& .MuiSlider-thumb": {
                                                width: "28px",
                                                height: "28px",
                                                backgroundColor: "transparent",
                                                border: "none",
                                                "&:hover": {
                                                    boxShadow: "none",
                                                },
                                                "&::before": {
                                                    boxShadow: "none",
                                                },
                                            },
                                            "& .MuiSlider-thumb.Mui-focusVisible": {
                                                boxShadow: "none",
                                            },
                                            "& .MuiSlider-thumb::after": {
                                                display: "none"
                                            },
                                            "& .MuiSlider-valueLabel": {
                                                padding: 0,
                                            },

                                        }}
                                />

                            </div>

                        </div>
                    </div>

                    <div className={style.bottom}>
                        <p className={style.bottomHeader}>
                            Calculator Features
                        </p>
                        <div className={style.items}>
                            {
                                [
                                    {
                                        icon: svgIcons.coin,
                                        label: "Input Box:",
                                        text: "Enter your investment amount",
                                    },
                                    {
                                        icon: svgIcons.star,
                                        label: "Dynamic Calculation:",
                                        text: "See real-time updates on token amounts and bonuses",
                                    },
                                ].map(({icon, label, text}, key) => (
                                    <div key={key}
                                         className={style.item}
                                    >
                                        {icon}
                                        <p>
                                            <span>{label}</span> <span>{text}</span>
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
})

