import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {svgIcons} from "../../../../assets/svgIcons";
import {TextField} from "../../../_common/TextField/TextField";
import {SelectField} from "../../SelectField/SelectField";
import {ButtonCustom} from "../../../_common/ButtonCustom/ButtonCustom";
import {Link} from "react-router-dom";
import style from "./Buy.module.scss"

interface IValues {
    receive: string
    pay: string
}

const initialValues: IValues = {
    receive: "",
    pay: "",
}

export const Buy = () => {

    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
        try {
            console.log(values);

        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    return (
        <div className={style.buy}>
            <p className={style.cardTitle}>
                Buy $FIX
            </p>

            <p className={style.currency}>
                1 $FIX = $0.005
            </p>

            <div className={style.line}>
                <div className={style.lineInner}/>
            </div>

            <div className={style.lineDescription}>
                <p>USD Raised</p>
                <p>$1,252.532 / $2,000.000</p>
            </div>

            <p className={style.info}>
                {svgIcons.infoCircle}
                <span>your purchased $FIX = 0</span>
            </p>

            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {
                    (props: FormikProps<IValues>) => (
                        <Form autoComplete="off"
                              className={style.form}
                        >

                            <div className={style.receiveField}>
                                <TextField label="You pay"
                                           name="pay"
                                />
                                <div className={style.select}>
                                    <SelectField/>
                                </div>
                            </div>

                            <TextField label="You Receive $FIX"
                                       name="Receive"
                                       className={style.receiveField}
                            />

                            <ButtonCustom label="Connect wallet"
                                          type="submit"
                                          className={style.submitBtn}
                            />

                        </Form>
                    )
                }
            </Formik>

            <Link to="/"
                  className={style.howToBuyLink}
            >
                How to Buy
            </Link>
        </div>
    )
}