import style from "./FairDistribution.module.scss"
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useRef} from "react";
import {clsx} from "clsx";
import {svgIcons} from "../../assets/svgIcons";

gsap.registerPlugin(ScrollTrigger);

export const FairDistribution = () => {

    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {

        gsap.to(".fairDistribution", {
            scrollTrigger: {
                trigger: ".wrapper",
                //markers: true,
                start: "center center",
                end: "bottom top",
                scrub: true,
                pin: true,
                //pinSpacing: true,
            }
        })

        gsap.fromTo(".tip0", {
                opacity: 0, top: -200,
            },
            {
                opacity: 1,
                top: 0,
                scrollTrigger: {
                    trigger: ".fairDistribution",
                    //markers: true,
                    start: "top top",
                    end: "top top-=25%",
                    scrub: true,
                }
            }
        )
        gsap.fromTo(".tip1", {
                opacity: 0, top: -200,
            },
            {
                opacity: 1,
                top: 0,
                scrollTrigger: {
                    trigger: ".fairDistribution",
                    //markers: true,
                    start: "top top-=25%",
                    end: "top top-=50%",
                    scrub: true,
                }
            }
        )

        gsap.fromTo(".tip2", {
                opacity: 0, top: -200,
            },
            {
                opacity: 1,
                top: 0,
                scrollTrigger: {
                    trigger: ".fairDistribution",
                    //markers: true,
                    start: "top top-=50%",
                    end: "top top-=75%",
                    toggleActions: "play none reverse none",
                }
            }
        )


    }, {scope: appRef})


    return (
        <div className={style.wrapper}
             ref={appRef}
        >
            <div className="wrapper">
                <div className={clsx(style.fairDistribution, "fairDistribution")}>

                    <div className={style.tips}>
                        {
                            [
                                "Own Claim Tool",
                                "No delayed distribution",
                                "You are in control",
                            ].map((label, key) => (
                                <div key={key}
                                     className={style.tipWrapper}
                                >
                                    <div className={clsx(style.tip, `tip${key}`)}>
                                        {svgIcons.check}<span>{label}</span>
                                    </div>

                                </div>
                            ))
                        }
                    </div>


                    <div className={style.inner}>

                        <h2 className={style.title}>
                            <p>
                                Fair Distribution
                            </p>
                            <p>
                                In Your Control
                            </p>
                        </h2>

                        <p className={style.description}>
                            Once the Pre-Sale has finished, the team will announce the token launch. Right after the
                            launch
                            a
                            claiming tool will be published where you simply claim your purchased $FIX token in order to
                            avoid
                            any delays!
                        </p>

                        <ButtonCustom label="Claim $FIX"
                                      variant={VariantEnum.contained}
                                      className={style.btn}
                        />


                    </div>
                </div>
            </div>

        </div>


    )
}