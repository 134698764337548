import {svgIcons} from "../../../assets/svgIcons";
import style from "./Card.module.scss";
import {clsx} from "clsx";
import {useState} from "react";
import {Buy} from "./Buy/Buy";
import {Stake} from "./Stake/Stake";
import {Transactions} from "./Transactions/Transactions";

export const Card = () => {

    const [tab, setTab] = useState(0);

    return (
        <div className={clsx({
            [style.card]: true,
            [style.card_bottomGradient]: tab === 2,
        })}>

            <div className={style.tabs}>
                {
                    [
                        {
                           icon:  svgIcons.coins,
                            label: "Buy",
                        },
                        {
                            icon:  svgIcons.lock,
                            label: "Stake",
                        },
                        {
                            icon:  svgIcons.clock,
                            label: "History",
                        },
                    ].map(({icon, label}, key) => (
                        <button key={key}
                                className={clsx({
                                    [style.tab]: true,
                                    [style.tab_active]: key === tab,
                                })}
                                onClick={() => setTab(key)}
                        >
                            {icon}<p>{label}</p>
                        </button>
                    ))
                }
            </div>

            <div className={style.cardBody}>
                {tab === 0 && <Buy/>}
                {tab === 1 && <Stake/>}
                {tab === 2 && <Transactions/>}
            </div>

        </div>
    )
}