import style from "./BuyTokens.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useRef} from "react";
import {MotionPathPlugin} from "gsap/MotionPathPlugin";
import {clsx} from "clsx";

gsap.registerPlugin(ScrollTrigger);

export const BuyTokens = () => {
    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {

        gsap.to(".progressInner", {
            width: "100%",
            height: "100%",
            scrollTrigger: {
                trigger: ".wrapper",
                start: "center center",
                end: "bottom top",
                pin: ".buyTokens",
                //markers: true,
                scrub: true,
            }
        })


    }, {scope: appRef})

    return (
        <div ref={appRef}>
            <div className={clsx(style.buyTokens, "buyTokens")}>
                <div className={style.inner}>

                    <h2 className={style.title}>
                        <p>
                            Buy $FIX Tokens
                        </p>
                        <p>
                            in {svgIcons.number3_mobile}{svgIcons.number3_desktop} easy steps
                        </p>
                    </h2>

                    <ButtonCustom label="Start Your Purchase"
                                  variant={VariantEnum.outlined}
                                  className={style.startBtn}
                    />

                    <div className={clsx(style.wrapper, "wrapper")}>

                        <div className={style.progress}>
                            <div className={clsx(style.progressInner, "progressInner")}/>
                        </div>

                        <div className={style.items}>
                            {
                                [
                                    {
                                        title: "Set Up Your Wallet",
                                        text: "Download and install a compatible wallet like MetaMask. Create your account and make sure to back up your seed phrase securely",
                                    },
                                    {
                                        title: "Fund Your Wallet",
                                        text: "Transfer ETH, BNB, or USDT to your wallet. These will be used to buy $FIX tokens during the presale",
                                    },
                                    {
                                        title: "Buy $FIX Tokens",
                                        text: "Connect your wallet to our Presale Dashboard and follow the instructions to purchase your tokens",
                                    },

                                ].map(({title, text}, key) => (
                                    <div key={key}
                                         className={style.item}
                                    >
                                        <p>{title}</p>
                                        <p>{text}</p>
                                    </div>
                                ))
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}