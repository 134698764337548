import style from "./WeNeverRunOut.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
// @ts-ignore
import video0 from "../../assets/webm/weNeverRunOut0.webm";
// @ts-ignore
import video1 from "../../assets/webm/weNeverRunOut1.webm";
import {Lines} from "../_common/Lines/Lines";


export const WeNeverRunOut = () => {
    return (
        <div className={style.weNeverRunOut}>

            <div className={style.linesWrapper}>
                <Lines count={4}/>
            </div>

            <div className={style.figure}>
                {svgIcons.backgroundFigure_WeNeverRunOut}
            </div>

            <div className={style.inner}>

                <p className={style.label}>
                    Get Paid On Time, Every Time  🤟
                </p>


                <h2 className={style.title}>
                    <p>
                        We never run
                    </p>
                    <p>
                        out of {svgIcons.funds} funds
                    </p>
                </h2>

                <p className={style.description}>
                    FundFix is a trading platform designed to back traders with firm capital. Our mission is to provide
                    access to fully funded accounts for traders looking to operate at a higher level. We combine
                    traditional and decentralized finance under one roof.
                </p>

                <ButtonCustom label="Learn More About the PLP"
                              variant={VariantEnum.outlined}
                              className={style.learnMoreBtn}
                />

                <div className={style.cards}>
                    {
                        [
                            {
                                title: "What It Means for Traders?",
                                items: [
                                    {
                                        icon: svgIcons.coin,
                                        label: "Reliable Payouts",
                                        text: "always get paid on time without delays",
                                    },
                                    {
                                        icon: svgIcons.star,
                                        label: "Stability",
                                        text: "token buy-backs and automated sales keep $FIX token value steady",
                                    },
                                    {
                                        icon: svgIcons.searchNormal,
                                        label: "Transparency",
                                        text: "all PLP transactions are publicly recorded on the blockchain",
                                    },
                                ],
                                src: video0,
                            },
                            {
                                title: "How it works?",
                                items: [
                                    {
                                        icon: svgIcons.tickCircle,
                                        label: "Ecosystem revenue",
                                        text: "a portion of every platform fee and profit goes into the PLP, keeping the pool fully funded",
                                    },
                                    {
                                        icon: svgIcons.tickCircle,
                                        label: "Automated Liquidity Management",
                                        text: "the PLP intelligently buys and sells $FIX tokens to maintain liquidity and stabilize payouts",
                                    },
                                    {
                                        icon: svgIcons.tickCircle,
                                        label: "Smart Algorithms",
                                        text: "automated algorithms manage PLP activities to ensure funds are always available",
                                    },
                                ],
                                src: video1,
                            },
                        ].map(({title, items, src}, key) => (
                            <div key={key}
                                 className={style.card}
                            >
                                <p className={style.cardTitle}>
                                    {title}
                                </p>

                                <div className={style.videoWrapper}>
                                    <video src={src}
                                           playsInline={true}
                                           loop={true}
                                           muted={true}
                                           autoPlay={true}
                                    />
                                </div>


                                <div className={style.items}>
                                    {
                                        items.map(({icon, label, text}, key) => (
                                            <div key={key}
                                                 className={style.item}
                                            >
                                                {icon}
                                                <p>
                                                    <span>{label}: </span>{text}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>


            </div>
        </div>
    )
}