import style from "./WhatsNext.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {Swiper as SwiperClass} from "swiper/types";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {slides} from "./data";
import {useRef, useState} from "react";
import {clsx} from "clsx";
import waveMobile from "../../assets/png/WhatsNext/WaveMobile.png";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {MotionPathPlugin} from "gsap/MotionPathPlugin";
import {Lines} from "../_common/Lines/Lines";


gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

export const WhatsNext = () => {

    const appRef = useRef<HTMLDivElement>(null!);
    const pathRef = useRef(null!);

    useGSAP(() => {

        const mm = gsap.matchMedia();

        mm.add("(min-width: 1440px)", (context) => {

            // gsap.timeline({
            //     scrollTrigger: {
            //         trigger: ".itemsDesktop",
            //         // markers: {
            //         //     startColor: "#FFF",
            //         //     endColor: "#FFF",
            //         // },
            //         start: "center center",
            //         end: "bottom top",
            //         scrub: true,
            //         pin: ".whatsNext",
            //     },
            // }).to(".ball", {
            //     ease: "none",
            //     motionPath: {
            //         path: pathRef.current,
            //         align: pathRef.current,
            //         start: 0.125,
            //         end: 0.875,
            //     }
            // })

            gsap.to(".itemsDesktop", {
                scrollTrigger: {
                    trigger: ".itemsDesktop",
                    // markers: {
                    //     startColor: "#FFF",
                    //     endColor: "#FFF",
                    // },
                    start: "center center",
                    end: "bottom top",
                    pin: ".whatsNext",
                },
            })

            //whiteWaveWrapper
            gsap.to(".whiteWaveWrapper", {
                width: "87.5%",
                scrollTrigger: {
                    trigger: ".itemsDesktop",
                    // markers: {
                    //     startColor: "red", endColor: "red",
                    // },
                    start: "center center",
                    end: "bottom top",
                    scrub: true,
                }
            })


            gsap.to(".label1", {
                background: "#FFF", color: "#000",
                scrollTrigger: {
                    trigger: ".itemsDesktop",
                    // markers: {
                    //     startColor: "red", endColor: "red",
                    // },
                    // start: "center center-=250px",
                    // end: "center center-=250pх",
                    start: "center center-=150px",
                    end: "center center-=150pх",
                    toggleActions: "play none reverse none",
                }
            })

            gsap.to(".label2", {
                background: "#FFF", color: "#000",
                scrollTrigger: {
                    trigger: ".itemsDesktop",
                    // markers: {
                    //     startColor: "red", endColor: "red",
                    // },
                    // start: "center center-=490px",
                    // end: "center center-=490pх",
                    start: "center center-=300px",
                    end: "center center-=300pх",
                    toggleActions: "play none reverse none",
                }
            })

            gsap.to(".label3", {
                background: "#FFF", color: "#000",
                scrollTrigger: {
                    trigger: ".itemsDesktop",
                    // markers: {
                    //     startColor: "red", endColor: "red",
                    // },
                    start: "bottom top",
                    end: "bottom top",
                    toggleActions: "play none reverse none",
                }
            })

        }, appRef)

    }, {scope: appRef})


    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const [index, setIndex] = useState(0);

    return (
        <div ref={appRef}>

            <div className={clsx(style.whatsNext, "whatsNext")}>

                <div className={style.linesWrapper}>
                    <Lines count={4}/>
                </div>

                <div className={style.figure}>
                    {svgIcons.backgroundFigure_FixToken}
                </div>


                <div className={style.topContent}>
                    <div className={style.inner}>

                        <h2 className={style.title}>
                            <p>
                                What’s {svgIcons.rightArrow} next
                            </p>
                            <p>
                                for the FundFix
                            </p>
                            {/*<p>*/}
                            {/*    Ecosystem*/}
                            {/*</p>*/}
                        </h2>

                        <p className={style.description}>
                            We have a clear vision for the future of trading. Here’s what we’re working on to make
                            FundFix
                            the top choice for traders.
                        </p>

                    </div>
                </div>

                <div className={style.sliderControl}>
                    <button onClick={() => swiper.slidePrev()}
                            className={clsx({
                                [style.btn]: true,
                                [style.btn_disable]: index === 0,
                            })}
                    >
                        {svgIcons.arrowRight}
                    </button>
                    <button onClick={() => swiper.slideNext()}
                            className={clsx({
                                [style.btn]: true,
                                [style.btn_disable]: index === slides.length - 1,
                            })}
                    >
                        {svgIcons.arrowRight}
                    </button>
                </div>

                <Swiper slidesPerView={1}
                        centeredSlides={true}
                        className={style.sliderMobile}
                        onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
                        onSlideChange={(swiper: SwiperClass) => setIndex(swiper.realIndex)}
                >
                    {
                        slides.map(({label, items}, key) => (
                            <SwiperSlide key={key}
                                         className={style.slide}
                            >
                                <p className={style.label}>
                                    {label}
                                </p>

                                <div className={style.items}>
                                    {
                                        items.map((item, key) => (
                                            <div key={key}
                                                 className={style.item}
                                            >
                                                {svgIcons.tickCircle}
                                                <p>
                                                    {item}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>

                                <img src={waveMobile} alt=""/>

                            </SwiperSlide>
                        ))
                    }
                </Swiper>

                <div className={clsx(style.itemsDesktop, "itemsDesktop")}>
                    <div className={style.inner}>

                        {/*<div className={clsx(style.ball, "ball")}/>*/}

                        <div className={style.svgWrapper}>

                            <svg width="1312" height="302" viewBox="0 0 1312 302" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path ref={pathRef}
                                      d="M0 301H3.68121C24.8641 301 44.6748 290.519 56.5921 273.006L103.42 204.192C132.666 161.215 196.185 161.711 224.757 205.14L268.86 272.176C280.697 290.168 300.79 301 322.326 301H328
                                        M328 301H331.681C352.864 301 372.675 290.519 384.592 273.006L431.42 204.192C460.666 161.215 524.185 161.711 552.757 205.14L596.86 272.176C608.697 290.168 628.79 301 650.326 301H656
                                        M656 301H659.681C680.864 301 700.675 290.519 712.592 273.006L759.42 204.192C788.666 161.215 852.185 161.711 880.757 205.14L924.86 272.176C936.697 290.168 956.79 301 978.326 301H984
                                        M984 301H987.681C1008.86 301 1028.67 290.519 1040.59 273.006L1087.42 204.192C1116.67 161.215 1180.19 161.711 1208.76 205.14L1252.86 272.176C1264.7 290.168 1284.79 301 1306.33 301H1312"
                                      stroke="white" strokeOpacity={0.1}/>
                            </svg>

                            <div className={clsx(style.whiteWaveWrapper, "whiteWaveWrapper")}>
                                {svgIcons.whatsNextWaveWhite}
                            </div>
                        </div>

                        {/*<svg width="1312" height="302" viewBox="0 0 1312 302" fill="none"*/}
                        {/*     xmlns="http://www.w3.org/2000/svg"*/}
                        {/*>*/}
                        {/*    <path ref={pathRef}*/}
                        {/*          d="M0 301V301C22.7551 301 43.3003 287.38 52.1611 266.421L138.551 62.0789C148.258 39.1185 180.865 39.3136 190.296 62.3886L272.623 263.81C281.816 286.302 303.701 301 328 301V301 M328 301H331.681C352.864 301 372.675 290.519 384.592 273.006L431.42 204.192C460.666 161.215 524.185 161.711 552.757 205.14L596.86 272.176C608.697 290.168 628.79 301 650.326 301H656 M656 301H659.681C680.864 301 700.675 290.519 712.592 273.006L759.42 204.192C788.666 161.215 852.185 161.711 880.757 205.14L924.86 272.176C936.697 290.168 956.79 301 978.326 301H984 M984 301H987.681C1008.86 301 1028.67 290.519 1040.59 273.006L1087.42 204.192C1116.67 161.215 1180.19 161.711 1208.76 205.14L1252.86 272.176C1264.7 290.168 1284.79 301 1306.33 301H1312"*/}
                        {/*          stroke="white" strokeMiterlimit="11.5197"*/}
                        {/*    />*/}
                        {/*</svg>*/}

                        <div className={style.cards}>
                            {
                                slides.map(({label, items}, key) => (
                                    <div key={key}
                                         className={style.card}>
                                        <p className={clsx(style.label, `label${key}`)}>
                                            {label}
                                        </p>

                                        <div className={style.items}>
                                            {
                                                items.map((item, key) => (
                                                    <div key={key}
                                                         className={style.item}
                                                    >
                                                        {svgIcons.tickCircle}
                                                        <p>
                                                            {item}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>


                    </div>


                </div>


            </div>
        </div>

    )
}