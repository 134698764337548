import style from "./TopContributors.module.scss";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import {data} from "./data";
import {svgIcons} from "../../assets/svgIcons";

export const TopContributors = () => {
    return (
        <div className={style.topContributors}>
            <div className={style.inner}>

                <h2 className={style.title}>
                    Top contributors Leading the way
                </h2>

                <p className={style.text}>
                    See who’s leading the presale and aim for the top. Top contributors will be rewarded with exclusive
                    bonuses and recognition. Check your position on the leaderboard and see who’s investing in FundFix
                </p>

                <ButtonCustom label="Check the Leadeboard"
                              variant={VariantEnum.outlined}
                              className={style.btn}
                />

                <div className={style.content}>

                    <div className={style.top}>

                        <div className={style.card}>
                            <div className={style.cardHeader}>
                                {
                                    ["Rank", "Wallet Address", "Deposit", "Tokens"].map((label, key) => (
                                        <p key={key}>{label}</p>
                                    ))
                                }
                            </div>
                            <div className={style.cardBody}>
                                {
                                    data.map(({address, deposit, tokens}, key) => (
                                        <div key={key}
                                             className={style.row}
                                        >
                                            <p><span>{key + 1}</span></p>
                                            <p>{address.slice(0, 5) + "..." + address.slice(address.length - 3, address.length)}</p>
                                            <p>{deposit}</p>
                                            <p>{tokens}</p>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>

                    <div className={style.bottom}>

                        <p className={style.bottomTitle}>
                            Leaderboard Features
                        </p>

                        <div className={style.bottomItems}>
                            {
                                [
                                    {
                                        icon: svgIcons.coin,
                                        title: "Live Ranking Updates:",
                                        text: "View the top contributors in real-time",
                                    },
                                    {
                                        icon: svgIcons.star,
                                        title: "Exclusive Bonuses:",
                                        text: "The top contributors get special rewards",
                                    },
                                    {
                                        icon: svgIcons.searchNormal,
                                        title: "Community Engagement:",
                                        text: "Be part of a growing community of early backers",
                                    },
                                ].map(({icon, title, text}, key) => (
                                    <div key={key}
                                         className={style.bottomItem}
                                    >
                                        {icon}
                                        <p>
                                            <span>{title}</span> <span>{text}</span>

                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}