import style from "./CreateAnAccount.module.scss";
import src from "../../assets/png/CreateAnAccount/src.png";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import {Lines} from "../_common/Lines/Lines";

export const CreateAnAccount = () => {
    return (
        <div className={style.createAnAccount}>
            <div className={style.inner}>

                <div className={style.linesWrapper}>
                    <Lines count={4}/>
                </div>
                <div className={style.linesWrapper}>
                    <Lines count={4}/>
                </div>

                <img src={src} alt=""/>

                <p className={style.title}>
                    Create An Account
                </p>

                <p className={style.description}>
                    Create a FundFix account to access <span>fully funded trading</span> accounts, powerful tools, and a
                    seamless
                    trading experience
                </p>

                <ButtonCustom label="Sign Up Now"
                              variant={VariantEnum.outlined}
                              className={style.signBtn}
                />


            </div>
        </div>
    )
}