import style from "./PresaleStages.module.scss"
import src0 from "../../assets/png/Presale Stages Breakdown/0.png";
import src1 from "../../assets/png/Presale Stages Breakdown/1.png";
import src2 from "../../assets/png/Presale Stages Breakdown/2.png";
import src20 from "../../assets/png/Presale Stages Breakdown/20.png";
import src21 from "../../assets/png/Presale Stages Breakdown/21.png";
import {clsx} from "clsx";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import {useRef} from "react";
import {useGSAP} from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

export const PresaleStages = () => {

    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {

        const mm = gsap.matchMedia();

        const cardsCount = 3;
        const paddingTop = 20;
        const cardHeight = 471;
        const cardsGap = 20;
        const cardHeader = 20 + 25 + 20;

        mm.add("(max-width: 1439px)", (context) => {

            Array.from(({length: cardsCount}), (v, k) => k)
                .forEach(key => {
                    gsap.to(`.card${key}`, {
                        scrollTrigger: {
                            trigger: `.card${cardsCount - 1}`,
                            //markers: true,
                            start: `top top+=${paddingTop + key * cardHeader + (cardsCount - (key + 1)) * (cardHeight + cardsGap)}px`,
                            end: `top top+=${paddingTop + (cardsCount - 1) * cardHeader}px`,
                            pin: `.card${key}`,
                            pinSpacing: false,
                        }
                    })
                })

        }, appRef)

    }, {scope: appRef})

    return (
        <div className={style.presaleStages}
             ref={appRef}
        >
            <div className={style.inner}>

                <h2 className={style.title}>
                    Presale Stages Breakdown
                </h2>

                <div className={style.topCards}>
                    {
                        [
                            {
                                title: "Private Sale",
                                src: src0,
                                items: [
                                    {
                                        label: "Price",
                                        value: "$0.010 per $FIX"
                                    },
                                    {
                                        label: "Bonus",
                                        value: "Early Access",
                                    },
                                    {
                                        label: "Min. Investment",
                                        value: "$5,000",
                                    },
                                    {
                                        label: "Vesting",
                                        value: "10% at TGE, 90% over 6m",
                                    },
                                ],
                            },
                            {
                                title: "Pre-Sale",
                                src: src1,
                                items: [
                                    {
                                        label: "Price",
                                        value: "$0.015 per $FIX",
                                    },
                                    {
                                        label: "Bonus",
                                        value: "20% Extra Tokens",
                                    },
                                    {
                                        label: "Min. Investment",
                                        value: "$500",
                                    },
                                    {
                                        label: "Vesting",
                                        value: "20% at TGE, 80% over 4m",
                                    },
                                ],
                            },
                            {
                                title: "Public Sale",
                                src: src2,
                                items: [
                                    {
                                        label: "Price",
                                        value: "$0.020 per $FIX",
                                    },
                                    {
                                        label: "Bonus",
                                        value: "Final Opportunity",
                                    },
                                    {
                                        label: "Min. Investment",
                                        value: "$100",
                                    },
                                    {
                                        label: "Vesting",
                                        value: "30% at TGE, 80% over 2m",
                                    },
                                ],
                            },
                        ].map(({title, src, items}, key) => (
                            <div key={key}
                                 className={clsx(style.topCard, `card${key}`)}
                            >
                                <p className={style.cardTitle}>
                                    {title}
                                </p>
                                <img src={src} alt="" className={style.img}/>
                                <div className={style.items}>
                                    {
                                        items.map(({label, value}, key) => (
                                            <div key={key}
                                                 className={style.item}
                                            >
                                                <p>{label}</p>
                                                <p>{value}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className={style.bottomCards}>
                    {
                        [
                            {
                                title: "Join Early",
                                text: "By participating in the presale, you can secure early access, benefit from bonuses, and be part of FundFix from the start. Our presale is structured to reward early participants and provide maximum value",
                                src: src20,
                            },
                            {
                                title: "Unlock Access",
                                text: "The $FIX token is your key to accessing premium trading features, revenue-sharing opportunities, and more on the FundFix platform. ",
                                src: src21,
                            },
                        ].map(({title, text, src}, key) => (
                            <div key={key}
                                 className={style.bottomCard}
                            >
                                <p>{title}</p>
                                <p>{text}</p>
                                <img src={src} alt=""/>
                            </div>
                        ))
                    }
                </div>


            </div>
        </div>
    )
}