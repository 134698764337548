import style from "./MadeByTraders.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import src0 from "../../assets/png/MadeByTraders/src0.png";
import src1 from "../../assets/png/MadeByTraders/src1.png";
import src2 from "../../assets/png/MadeByTraders/src2.png";
import src3 from "../../assets/png/MadeByTraders/src3.png";
import src4 from "../../assets/png/MadeByTraders/src4.png";
import src5 from "../../assets/png/MadeByTraders/src5.png";
import src6 from "../../assets/png/MadeByTraders/src6.png";
import {useRef, useState} from "react";
import {clsx} from "clsx";
// @ts-ignore
import video0 from "../../assets/webm/madeBytraders0.webm";
// @ts-ignore
import video1 from "../../assets/webm/madeBytraders1.webm";
// @ts-ignore
import video2 from "../../assets/webm/madeBytraders2.webm";
// @ts-ignore
import video3 from "../../assets/webm/madeBytraders3.webm";
// @ts-ignore
import video4 from "../../assets/webm/madeBytraders4.webm";
// @ts-ignore
import video5 from "../../assets/webm/madeBytraders5.webm";
// @ts-ignore
import video6 from "../../assets/webm/madeBytraders6.webm";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import {Lines} from "../_common/Lines/Lines";


gsap.registerPlugin(ScrollTrigger);

export const MadeByTraders = () => {

    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {

        const mm = gsap.matchMedia();

        const cardsCount = 7;
        const paddingTop = 16;
        const cardHeight = 400;
        const cardsGap = 16;
        const cardHeader = 20 + 22 + 20;

        mm.add("(max-width: 1439px)", (context) => {

            Array.from(({length: cardsCount}), (v, k) => k)
                .forEach(key => {
                    gsap.to(`.card${key}`, {
                        scrollTrigger: {
                            trigger: `.card${cardsCount - 1}`,
                            //markers: true,
                            start: `top top+=${paddingTop + key * cardHeader + (cardsCount - (key + 1)) * (cardHeight + cardsGap)}px`,
                            end: `top top+=${paddingTop + (cardsCount - 1) * cardHeader}px`,
                            pin: `.card${key}`,
                            pinSpacing: false,
                        }
                    })
                })

        }, appRef)

    }, {scope: appRef})


    const [mouseDownCardIndex, setMouseDownCardIndex] = useState(-1);

    return (
        <div className={style.madeByTraders}
             ref={appRef}
        >
            <div className={style.inner}>

                <h2 className={style.title}>
                    <p>
                        Made by traders
                    </p>
                    <p>
                        for {svgIcons.candlestick} trades
                    </p>
                </h2>

                <div className={style.linesWrapper}>
                    <Lines count={4}/>
                </div>

                <p className={style.description}>
                    FundFix is designed for traders who want to achieve better results. With cutting-edge tools,
                    reliable funding options, and a powerful trading environment, our platform gives you the resources
                    to succeed
                </p>

                <ButtonCustom label="Explore all Features"
                              variant={VariantEnum.outlined}
                              className={style.exploreBtn}
                />

                <div className={style.cards}>
                    {
                        [
                            {
                                label: "FundFix Crypto Prop Trading",
                                description: "Secure capital through our challenges and access trading accounts with real funds",
                                src: video0,
                            },
                            {
                                label: "FundFix OnChain",
                                description: "Trade microcaps with funded wallets, additional leverage and instant payouts",
                                src: video1,
                            },
                            {
                                label: "FundFix Prime",
                                description: "Gain exposure to traditional markets with high-volume trading accounts",
                                src: video2,
                            },
                            {
                                label: "FundFix Academy",
                                description: "Enhance your trading strategies with practical courses",
                                src: video3,
                            },
                            {
                                label: "FundFix Dashboard",
                                description: "Enhance your trading strategies with practical courses",
                                src: video4,
                            },
                            {
                                label: "AI Signals & Copy Trading",
                                description: "Utilize AI-powered insights or follow top traders to maximize profits",
                                src: video5,
                            },
                            {
                                label: "Crypto Card Integration",
                                description: "Transform your crypto into cash—anytime, anywhere",
                                src: video6,
                            },
                        ].map(({label, description, src}, key) => (
                            <div key={key}
                                 className={clsx(style.card, `card${key}`)}
                            >
                                <div className={style.top}>
                                    <p className={style.label}>
                                        {label}
                                    </p>
                                    <p className={style.count}>
                                        / 0{key + 1} /
                                    </p>
                                </div>

                                {/*<img src={src} alt=""/>*/}
                                <div className={style.videoWrapper}>
                                    <video src={src}
                                           playsInline={true}
                                           autoPlay={true}
                                           muted={true}
                                           loop={true}
                                    />
                                </div>


                                <div className={style.bottom}>
                                    <p className={style.bottomDescription}>
                                        {description}
                                    </p>
                                    <a className={clsx({
                                        [style.link]: true,
                                        [style.link_mouseDown]: mouseDownCardIndex === key,
                                    })}
                                       href="#"
                                       target="_blank"
                                       rel="noreferrer noopener nofollow"
                                       onMouseDown={() => setMouseDownCardIndex(key)}
                                       onMouseUp={() => setMouseDownCardIndex(-1)}
                                    >
                                        Learn more
                                    </a>
                                </div>

                            </div>
                        ))
                    }
                    <div className={style.backgroundFigureMadeByTraders}>
                        {svgIcons.backgroundFigure_MadeByTraders}
                    </div>
                </div>

            </div>
        </div>
    )
}