import {HTMLAttributes} from "react";
import {SliderThumb} from "@mui/material/Slider";
import {svgIcons} from "../../../assets/svgIcons";
import style from "./ThumbComponent.module.scss"

interface ThumbComponentProps extends HTMLAttributes<unknown> {}
export const ThumbComponent = (props: ThumbComponentProps) => {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
           <div className={style.thumbComponent}>
               <div className={style.inner}>
                   {svgIcons.threeVerticalLines}
               </div>
           </div>
        </SliderThumb>
    );
}