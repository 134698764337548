import style from "./JoinTheFix2.module.scss"
import back from "../../assets/png/WeFund/background.png";
import {svgIcons} from "../../assets/svgIcons";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {TextField} from "../_common/TextField/TextField";
import {ButtonCustom} from "../_common/ButtonCustom/ButtonCustom";
import {Lines} from "../_common/Lines/Lines";

interface IValues {
    email: string
}

const initialValues: IValues = {
    email: "",
}


export const JoinTheFix2 = () => {

    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
        try {
            console.log(values);

        } catch (e) {
            console.log(e)
        } finally {

        }
    }


    return (
        <div className={style.joinTheFix2}>

            <div className={style.linesWrapper}>
                <Lines count={4}/>
            </div>

            <img src={back} alt="" className={style.back}/>

            <div className={style.inner}>

                <h1 className={style.title}>
                    <p>
                        Join the $FIX
                    </p>
                    <p>
                        Presale Whitelist
                    </p>
                </h1>

                <div className={style.content}>

                    <div className={style.timerSection}>

                        <div className={style.card}>

                            <p className={style.cardTitle}>
                                Presale countdown timer
                            </p>

                            <div className={style.progress}>
                                <div className={style.progressValue}/>
                            </div>

                            <div className={style.timerValues}>
                                {
                                    [
                                        {
                                            value: "04",
                                            label: "Month",
                                        },
                                        {
                                            value: "24",
                                            label: "Days",
                                        },
                                        {
                                            value: "19",
                                            label: "Hours",
                                        },
                                    ].map(({value, label}, key) => (
                                        <div key={key}
                                             className={style.timerItem}
                                        >
                                            <p>{value}</p>
                                            <p>{label}</p>

                                        </div>
                                    ))
                                }
                            </div>

                            <Formik initialValues={initialValues}
                                    onSubmit={onSubmit}
                            >
                                {
                                    (props: FormikProps<IValues>) => (
                                        <Form autoComplete="off"
                                              className={style.form}
                                        >

                                            <div className={style.fieldWrapper}>
                                                <TextField label="Enter your email"
                                                           name="email"
                                                           type="text"

                                                />
                                                <button className={style.submitBtnMobile}
                                                        type="submit"
                                                >
                                                    {svgIcons.send}
                                                </button>
                                            </div>

                                            <ButtonCustom label="Connect wallet"
                                                          type="submit"
                                                          className={style.submitBtnDesktop}
                                            />

                                        </Form>
                                    )
                                }
                            </Formik>

                            <p className={style.info}>
                                Get exclusive <span>early access</span> to the FundFix presale
                            </p>

                        </div>


                    </div>

                    <div className={style.itemsSection}>
                        {
                            [
                                {
                                    label: "Secure Your Spot",
                                    text: "By joining the whitelist, you'll secure your spot to buy tokens at the lowest price before they go public."
                                },
                                {
                                    label: "Get Priority Benefits",
                                    text: "Enjoy priority allocation and special bonuses for being part of our early supporters. Don’t miss out—limited spots available. Sign up now to lock in your advantage!"
                                },
                            ].map(({label, text}, key) => (
                                <div key={key}
                                     className={style.item}
                                >
                                    <p>{label}</p>
                                    <p>{text}</p>
                                </div>
                            ))
                        }
                    </div>


                </div>


            </div>
        </div>
    )
}