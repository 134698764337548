import style from "./WeFund.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import back from "../../assets/png/WeFund/background.png";
// @ts-ignore
import src from "../../assets/webm/weFund.webm"
import src0 from "../../assets/png/WeFund/0.png";
import src1 from "../../assets/png/WeFund/1.png";
import src2 from "../../assets/png/WeFund/2.png";
import gsap from "gsap";
import {useRef} from "react";
import {useGSAP} from "@gsap/react";
import {clsx} from "clsx";
import {Lines} from "../_common/Lines/Lines";

export const WeFund = () => {
    const appRef = useRef<HTMLDivElement>(null!);
    const duration = 1.2;

    useGSAP(() => {

        gsap.timeline({repeat: -1})
            .to(".card0", {
                y: -5, ease: "power1.out", duration,
            })
            .to(".card0", {
                y: 0, ease: "power1.in", duration,
            })
            .to(".card0", {
                y: 5, ease: "power1.out", duration,
            })
            .to(".card0", {
                y: 0, ease: "power1.in", duration,
            })

        gsap.timeline({repeat: -1})
            .to(".card1", {
                y: 5, ease: "power1.out", duration,
            })
            .to(".card1", {
                y: 0, ease: "power1.in", duration,
            })
            .to(".card1", {
                y: -5, ease: "power1.out", duration,
            })
            .to(".card1", {
                y: 0, ease: "power1.in", duration,
            })

        gsap.timeline({repeat: -1})
            .to(".card2", {
                y: -5, ease: "power1.out", duration,
            })
            .to(".card2", {
                y: 0, ease: "power1.in", duration,
            })
            .to(".card2", {
                y: 5, ease: "power1.out", duration,
            })
            .to(".card2", {
                y: 0, ease: "power1.in", duration,
            })


    }, {scope: appRef})


    return (
        <div className={style.weFund}
             ref={appRef}
        >

            <img src={back} alt="" className={style.back}/>

            <div className={style.inner}>

                <div className={style.linesWrapper}>
                    <Lines count={4}/>
                </div>

                <p className={style.label}>
                    Join Us, Start Trading <span>&</span> Prove Your Skills 🤟
                </p>

                <h1 className={style.title}>
                    <p>
                        We fund your
                    </p>
                    <p>
                        <span>crypto</span> {svgIcons.growthChart} trades
                    </p>
                </h1>

                <div className={style.description}>
                    <p>
                        <span>Prove Your Skills</span>, We’ll Fund the Rest. Trade Crypto & Stocks Risk-Free with
                        FundFix. <span>Pass our challenge</span>, claim a fully funded account, and <span>trade like a pro</span> without
                        risking your own capital.

                    </p>
                    <br/>
                    <p>We’re here to back your potential — <span>are you ready</span> to take it on?</p>
                </div>

                <div className={style.line}>
                    <div className={style.lineInner}/>
                </div>

                <div className={style.lineDescription}>
                    <p>USD Raised</p>
                    <p>$1,252.532 / $2,000.000</p>
                </div>

                <ButtonCustom label="BUY NOW"
                              variant={VariantEnum.outlined}
                              className={style.getStartedNowBtn}
                />

                <div className={style.videoWrapper}>

                    <div className={style.linesWrapper}>
                        <Lines count={4}/>
                    </div>

                    <div className={style.linesWrapper}>
                        <Lines count={4}/>
                    </div>

                    <video src={src}
                           playsInline={true}
                           loop={true}
                           muted={true}
                           autoPlay={true}
                           className={style.video}
                    />

                    <div className={style.cards}>
                        {
                            [
                                {
                                    name: "Alex D&D",
                                    funded: 60,
                                    src: src0,
                                },
                                {
                                    name: "Adam Smeet",
                                    funded: 40,
                                    src: src1,
                                },
                                {
                                    name: "Olenka",
                                    funded: 200,
                                    src: src2,
                                },
                            ].map(({name, funded, src}, key) => (
                                <div key={key}
                                     className={clsx(style.card, `card${key}`)}
                                >
                                    <img src={src} alt=""/>
                                    <div>
                                        <p>{name}</p>
                                        <p>Funded <span>${funded}K</span></p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}