import {JoinTheFix2} from "../D1_JoinTheFix2/JoinTheFix2";
import {HavingTrouble} from "../B8_HavingTrouble/HavingTrouble";

export const Presale2 = () => {
    return (
        <>
            <JoinTheFix2/>
            <HavingTrouble/>
        </>
    )
}