import {WhoWeAre} from "../components/B3_WhoWeAre/WhoWeAre";
import {WeNeverRunOut} from "../components/B4_WeNeverRunOut/WeNeverRunOut";
import {FixToken} from "../components/B5_FixToken/FixToken";
import {WhatsNext} from "../components/B6_WhatsNext/WhatsNext";

export const svgIcons = {
    logo: (
        <svg width="99" height="24" viewBox="0 0 99 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.94358 0.265561V3.12033C9.43535 2.89903 8.90503 2.78838 8.35261 2.78838C6.73954 2.78838 5.933 3.75104 5.933 5.67635V6.93776H9.47955V9.46058H5.933V23.7676H2.68477V9.46058H0V6.93776H2.68477V5.64315C2.68477 3.89488 3.1488 2.52282 4.07687 1.52697C5.02703 0.50899 6.35284 0 8.0543 0C8.67301 0 9.30277 0.0885202 9.94358 0.265561Z"
                fill="white"/>
            <path
                d="M22.8034 6.93776H26.0517V23.7676H22.8034V20.8797C21.9196 22.9378 20.1739 23.9668 17.5665 23.9668C15.9092 23.9668 14.5944 23.4799 13.6222 22.5062C12.6499 21.5104 12.1638 20.1494 12.1638 18.4232V6.93776H15.4452V17.6266C15.4452 18.7773 15.7214 19.6625 16.2738 20.2822C16.8483 20.8797 17.6328 21.1784 18.6271 21.1784C19.8866 21.1784 20.892 20.7358 21.6433 19.8506C22.4167 18.9654 22.8034 17.7151 22.8034 16.0996V6.93776Z"
                fill="white"/>
            <path
                d="M33.8667 6.93776V9.82573C34.8611 7.7455 36.6067 6.70539 39.1036 6.70539C40.7609 6.70539 42.0757 7.21438 43.0479 8.23236C44.0202 9.25035 44.5063 10.6335 44.5063 12.3817V23.7676H41.2581V13.1452C41.2581 11.9723 40.9598 11.0761 40.3632 10.4564C39.7886 9.83679 39.0153 9.52697 38.043 9.52697C36.894 9.52697 35.9107 9.98064 35.0931 10.888C34.2755 11.7732 33.8667 13.0235 33.8667 14.639V23.7676H30.6185V6.93776H33.8667Z"
                fill="white"/>
            <path
                d="M55.669 21.1452C56.9506 21.1452 58.0334 20.6584 58.9173 19.6846C59.8011 18.7109 60.2431 17.2282 60.2431 15.2365C60.2431 13.3112 59.779 11.8838 58.851 10.9544C57.945 10.0249 56.9175 9.56017 55.7685 9.56017C54.4648 9.56017 53.3931 10.0802 52.5534 11.1203C51.7137 12.1383 51.2939 13.5989 51.2939 15.5021C51.2939 17.184 51.7026 18.545 52.5202 19.5851C53.3599 20.6252 54.4095 21.1452 55.669 21.1452ZM60.2431 0.298755H63.5245V23.7676H60.2431V21.2116C59.0719 23.0705 57.3594 24 55.1056 24C52.8959 24 51.1502 23.1701 49.8686 21.5104C48.587 19.8285 47.9462 17.8036 47.9462 15.4357C47.9462 12.8022 48.6201 10.6888 49.968 9.09544C51.316 7.50207 53.0506 6.70539 55.1719 6.70539C57.3594 6.70539 59.0498 7.63485 60.2431 9.49378V0.298755Z"
                fill="white"/>
            <path
                d="M81.3923 6.93776V23.7676H78.1441V9.46058H72.7414V23.7676H69.4932V9.46058H66.8084V6.93776H69.4932V5.64315C69.4932 3.87275 69.9683 2.48963 70.9184 1.49378C71.8686 0.497925 73.2165 0 74.9622 0C76.752 0 78.4424 0.409405 80.0334 1.22822V4.04979C78.2435 3.20885 76.6526 2.78838 75.2605 2.78838C73.5811 2.78838 72.7414 3.75104 72.7414 5.67635V6.93776H81.3923Z"
                fill="white"/>
            <path
                d="M93.3984 15.2365L99 23.7676H95.3209L91.6417 17.5934L87.7637 23.7676H84.1841L89.9182 15.2033L84.5487 6.90456H88.2278L91.7412 12.8465L95.354 6.90456H99L93.3984 15.2365Z"
                fill="white"/>
        </svg>
    ),
    burger: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="0.5" y="6.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="0.5" y="12.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="6.5" y="0.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="6.5" y="6.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="6.5" y="12.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="12.5" y="0.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="12.5" y="6.5" width="3" height="3" rx="1.5" fill="white"/>
            <rect x="12.5" y="12.5" width="3" height="3" rx="1.5" fill="white"/>
        </svg>
    ),
    growthChart: (
        <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_392_26902)">
                <path
                    d="M48.75 13.5V20H53.95L35.75 38.2L22.75 25.2L0 47.95L4.55 52.5L22.75 34.3L35.75 47.3L58.5 24.55V29.75H65V13.5H48.75Z"
                    fill="#F7FA5A"/>
            </g>
            <defs>
                <clipPath id="clip0_392_26902">
                    <rect width="65" height="65" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    ),
    candlestick: (
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.668 18.233V38.7663C20.668 39.2815 20.22 39.6997 19.668 39.6997H16.668V46.233C16.668 46.7482 16.22 47.1663 15.668 47.1663H13.668C13.116 47.1663 12.668 46.7482 12.668 46.233V39.6997H9.66797C9.11597 39.6997 8.66797 39.2815 8.66797 38.7663V18.233C8.66797 17.7178 9.11597 17.2997 9.66797 17.2997H12.668V10.7663C12.668 10.2511 13.116 9.83301 13.668 9.83301H15.668C16.22 9.83301 16.668 10.2511 16.668 10.7663V17.2997H19.668C20.22 17.2997 20.668 17.7178 20.668 18.233Z"
                fill="#F7FA5A"/>
            <path
                d="M38 35.6371V50.6959C38 51.2154 37.552 51.6371 37 51.6371H34V58.2253C34 58.7449 33.552 59.1665 33 59.1665H31C30.448 59.1665 30 58.7449 30 58.2253V51.6371H27C26.448 51.6371 26 51.2154 26 50.6959V35.6371C26 35.1176 26.448 34.6959 27 34.6959H30V28.1077C30 27.5882 30.448 27.1665 31 27.1665H33C33.552 27.1665 34 27.5882 34 28.1077V34.6959H37C37.552 34.6959 38 35.1176 38 35.6371Z"
                fill="#F7FA5A"/>
            <path
                d="M55.332 14.333V42.6663C55.332 43.1877 54.884 43.6108 54.332 43.6108H51.332V50.2219C51.332 50.7432 50.884 51.1663 50.332 51.1663H48.332C47.78 51.1663 47.332 50.7432 47.332 50.2219V43.6108H44.332C43.78 43.6108 43.332 43.1877 43.332 42.6663V14.333C43.332 13.8117 43.78 13.3886 44.332 13.3886H47.332V6.77745C47.332 6.25612 47.78 5.83301 48.332 5.83301H50.332C50.884 5.83301 51.332 6.25612 51.332 6.77745V13.3886H54.332C54.884 13.3886 55.332 13.8117 55.332 14.333Z"
                fill="#F7FA5A"/>
        </svg>
    ),
    user: (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_392_29721)">
                <path
                    d="M57.1012 20.8958C57.1012 24.2151 54.3855 26.9308 51.0662 26.9308C47.747 26.9308 45.0312 24.2151 45.0312 20.8958C45.0312 17.5766 47.747 14.8608 51.0662 14.8608C54.3855 14.8608 57.1012 17.5766 57.1012 20.8958Z"
                    fill="#F7FA5A"/>
                <path
                    d="M20.3686 20.6291C20.3686 24.0767 17.5479 26.8975 14.1003 26.8975C10.6528 26.8975 7.83203 24.0767 7.83203 20.6291C7.83203 17.1816 10.6528 14.3608 14.1003 14.3608C17.5479 14.3608 20.3686 17.1816 20.3686 20.6291Z"
                    fill="#F7FA5A"/>
                <path
                    d="M64.001 40.7253H38.1367C38.4446 34.0264 44.1101 28.6553 51.0688 28.6553C58.0276 28.6553 63.6931 34.0264 64.001 40.7253Z"
                    fill="#F7FA5A"/>
                <path
                    d="M27.5321 41.2251H0.667969C0.98778 34.2673 6.87231 28.6885 14.1001 28.6885C21.3278 28.6885 27.2123 34.2673 27.5321 41.2251Z"
                    fill="#F7FA5A"/>
                <path
                    d="M33.1864 27.6164C38.9227 27.6164 43.6139 22.9252 43.6139 17.1888C43.6139 11.4525 38.9227 6.76129 33.1864 6.76129C27.45 6.76129 22.7588 11.4525 22.7588 17.1888C22.7588 22.9252 27.45 27.6164 33.1864 27.6164Z"
                    fill="#F7FA5A" stroke="black" strokeWidth="1.06043"/>
                <path
                    d="M54.3956 50.2389H54.9508L54.9253 49.6844C54.4069 38.4064 44.8737 29.3838 33.1871 29.3838C21.5004 29.3838 11.9672 38.4064 11.4489 49.6844L11.4234 50.2389H11.9785H54.3956Z"
                    fill="#F7FA5A" stroke="black" strokeWidth="1.06043"/>
            </g>
            <defs>
                <clipPath id="clip0_392_29721">
                    <rect width="64" height="64" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    ),
    funds: (
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.7229 42.394L9.58411 33.9721C9.08881 33.6956 8.44645 33.8452 8.14927 34.3045C7.85209 34.7637 8.01243 35.3593 8.50773 35.6349L24.1939 44.3617C24.3593 44.4535 24.5462 44.4999 24.7321 44.4999C24.9231 44.4999 25.1151 44.4516 25.2836 44.3541L34.6667 38.9512V36.6742C34.6575 36.6791 34.6473 36.6798 34.6381 36.685L24.7229 42.394Z"
                fill="#F7FA5A"/>
            <path
                d="M8.50773 40.9684L24.1939 49.6952C24.3593 49.787 24.5462 49.8334 24.7321 49.8334C24.9231 49.8334 25.1151 49.7851 25.2836 49.6876L34.6667 44.2847V42.0077C34.6575 42.0126 34.6473 42.0133 34.6381 42.0185L24.7229 47.7275L9.58411 39.3056C9.08881 39.0291 8.44645 39.1787 8.14927 39.6379C7.85209 40.0972 8.01243 40.6928 8.50773 40.9684Z"
                fill="#F7FA5A"/>
            <path
                d="M44.991 25.6484C45.1412 25.7406 45.2323 25.9032 45.2323 26.0787V27.1668C45.2413 27.1617 45.2516 27.16 45.2604 27.1546L55.514 20.8494C55.8174 20.663 56.0017 20.3328 55.9987 19.9789C55.9957 19.6249 55.8074 19.2987 55.501 19.1162L40.1205 9.97828C39.7911 9.78295 39.3775 9.78493 39.0521 9.98621L29.332 15.9633L44.991 25.6484Z"
                fill="#F7FA5A"/>
            <path
                d="M35.8484 32.0606V49.8333L44 44.6816V26.563L28.1954 16.5L20 21.6852L35.6086 31.6203C35.7579 31.7147 35.8484 31.881 35.8484 32.0606Z"
                fill="#F7FA5A"/>
            <path
                d="M8.50759 29.7312L24.1938 39.0194C24.3593 39.1172 24.5462 39.1666 24.732 39.1666C24.923 39.1666 25.115 39.1152 25.2835 39.0114L34.6667 33.2609V31.7852L18.5168 21.8333L8.49432 27.9695C8.18488 28.159 7.99697 28.4946 8.00004 28.8544C8.0031 29.2142 8.19509 29.5457 8.50759 29.7312Z"
                fill="#F7FA5A"/>
            <path
                d="M54.447 24.6485L45.332 30.1715V32.5C45.341 32.4949 45.3509 32.4935 45.3598 32.4881L55.5184 26.3328C55.9956 26.0443 56.1414 25.4326 55.8458 24.968C55.5492 24.5033 54.9232 24.361 54.447 24.6485Z"
                fill="#F7FA5A"/>
            <path
                d="M34.6667 46.3625C34.6575 46.368 34.6473 46.3688 34.6381 46.3746L24.7229 52.7975L9.58411 43.3225C9.08881 43.0125 8.44645 43.1797 8.14927 43.6964C7.85209 44.2131 8.01243 44.8832 8.50773 45.1932L24.1939 55.0112C24.3593 55.1145 24.5462 55.1667 24.7321 55.1667C24.9231 55.1667 25.1151 55.1124 25.2836 55.0027L34.6667 48.9242V46.3625Z"
                fill="#F7FA5A"/>
            <path
                d="M54.447 29.982L45.332 35.505V37.8335C45.341 37.8284 45.3509 37.827 45.3598 37.8216L55.5184 31.6663C55.9956 31.3778 56.1414 30.7661 55.8458 30.3015C55.5492 29.8368 54.9232 29.6945 54.447 29.982Z"
                fill="#F7FA5A"/>
            <path
                d="M54.447 35.315L45.332 40.838V43.1665C45.341 43.1614 45.3509 43.16 45.3598 43.1546L55.5184 36.9993C55.9956 36.7108 56.1414 36.0991 55.8458 35.6345C55.5492 35.1698 54.9232 35.0275 54.447 35.315Z"
                fill="#F7FA5A"/>
        </svg>
    ),
    coin: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.9199 16.7501C21.5899 19.4101 19.4099 21.5901 16.7499 21.9201C15.1399 22.1201 13.6399 21.6801 12.4699 20.8201C11.7999 20.3301 11.9599 19.2901 12.7599 19.0501C15.7699 18.1401 18.1399 15.7601 19.0599 12.7501C19.2999 11.9601 20.3399 11.8001 20.8299 12.4601C21.6799 13.6401 22.1199 15.1401 21.9199 16.7501Z"
                fill="white"/>
            <path
                d="M9.99 2C5.58 2 2 5.58 2 9.99C2 14.4 5.58 17.98 9.99 17.98C14.4 17.98 17.98 14.4 17.98 9.99C17.97 5.58 14.4 2 9.99 2ZM9.05 8.87L11.46 9.71C12.33 10.02 12.75 10.63 12.75 11.57C12.75 12.65 11.89 13.54 10.84 13.54H10.75V13.59C10.75 14 10.41 14.34 10 14.34C9.59 14.34 9.25 14 9.25 13.59V13.53C8.14 13.48 7.25 12.55 7.25 11.39C7.25 10.98 7.59 10.64 8 10.64C8.41 10.64 8.75 10.98 8.75 11.39C8.75 11.75 9.01 12.04 9.33 12.04H10.83C11.06 12.04 11.24 11.83 11.24 11.57C11.24 11.22 11.18 11.2 10.95 11.12L8.54 10.28C7.68 9.98 7.25 9.37 7.25 8.42C7.25 7.34 8.11 6.45 9.16 6.45H9.25V6.41C9.25 6 9.59 5.66 10 5.66C10.41 5.66 10.75 6 10.75 6.41V6.47C11.86 6.52 12.75 7.45 12.75 8.61C12.75 9.02 12.41 9.36 12 9.36C11.59 9.36 11.25 9.02 11.25 8.61C11.25 8.25 10.99 7.96 10.67 7.96H9.17C8.94 7.96 8.76 8.17 8.76 8.43C8.75 8.77 8.81 8.79 9.05 8.87Z"
                fill="white"/>
        </svg>
    ),
    star: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.7299 3.50989L15.4899 7.02989C15.7299 7.51989 16.3699 7.98989 16.9099 8.07989L20.0999 8.60989C22.1399 8.94989 22.6199 10.4299 21.1499 11.8899L18.6699 14.3699C18.2499 14.7899 18.0199 15.5999 18.1499 16.1799L18.8599 19.2499C19.4199 21.6799 18.1299 22.6199 15.9799 21.3499L12.9899 19.5799C12.4499 19.2599 11.5599 19.2599 11.0099 19.5799L8.01991 21.3499C5.87991 22.6199 4.57991 21.6699 5.13991 19.2499L5.84991 16.1799C5.97991 15.5999 5.74991 14.7899 5.32991 14.3699L2.84991 11.8899C1.38991 10.4299 1.85991 8.94989 3.89991 8.60989L7.08991 8.07989C7.61991 7.98989 8.25991 7.51989 8.49991 7.02989L10.2599 3.50989C11.2199 1.59989 12.7799 1.59989 13.7299 3.50989Z"
                fill="white"/>
        </svg>
    ),
    searchNormal: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                fill="white"/>
            <path
                d="M21.2996 22.0001C21.1196 22.0001 20.9396 21.9301 20.8096 21.8001L18.9496 19.9401C18.6796 19.6701 18.6796 19.2301 18.9496 18.9501C19.2196 18.6801 19.6596 18.6801 19.9396 18.9501L21.7996 20.8101C22.0696 21.0801 22.0696 21.5201 21.7996 21.8001C21.6596 21.9301 21.4796 22.0001 21.2996 22.0001Z"
                fill="white"/>
        </svg>
    ),
    tickCircle: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                fill="white"/>
        </svg>
    ),
    graph: (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_392_32499)">
                <path
                    d="M33.9897 26.6604C34.1557 26.7933 34.355 26.8708 34.5654 26.8708C34.6096 26.8708 34.665 26.8708 34.7093 26.8597L59.0098 22.9296C59.4969 22.8521 59.818 22.3982 59.7405 21.911C58.7884 15.9992 55.755 10.5745 51.1827 6.64433C46.5551 2.68096 40.6543 0.5 34.5654 0.5C34.0782 0.5 33.6797 0.898551 33.6797 1.38567V25.9962C33.6797 26.2508 33.7904 26.4944 33.9897 26.6604Z"
                    fill="#FA5A5A"/>
                <path
                    d="M30.5381 29.7271V5.40447C30.5381 4.91735 30.1396 4.5188 29.6524 4.5188C22.8439 4.5188 16.4449 7.1758 11.6291 11.9806C6.81326 16.7964 4.15625 23.1953 4.15625 30.015C4.15625 36.8346 6.81326 43.2225 11.6291 48.0383C16.4449 52.8542 22.8439 55.5001 29.6524 55.5001C34.9664 55.5001 40.0701 53.8727 44.3877 50.8061C44.7863 50.5182 44.8749 49.9757 44.5981 49.5772L30.5381 29.7271Z"
                    fill="#F7FA5A"/>
                <path
                    d="M60.5201 26.1955C60.4869 25.963 60.3541 25.7527 60.1659 25.6198C59.9776 25.487 59.7341 25.4206 59.5016 25.4648L35.2011 29.395C34.9022 29.4393 34.6475 29.6496 34.5257 29.9264C34.404 30.2032 34.4483 30.5353 34.6254 30.7788L48.8514 50.8614C48.9843 51.0496 49.1946 51.1824 49.4271 51.2267C49.4714 51.2378 49.5268 51.2378 49.571 51.2378C49.7482 51.2378 49.9364 51.1824 50.0803 51.0717C53.3351 48.7579 56.0364 45.6802 57.8963 42.1597C59.8227 38.5174 60.8301 34.4101 60.8301 30.2696C60.8412 28.9189 60.7305 27.5462 60.5201 26.1955Z"
                    fill="#6A5AFA"/>
            </g>
            <defs>
                <clipPath id="clip0_392_32499">
                    <rect width="64" height="64" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    ),
    rightArrow: (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 12H20.0195L35.0884 32.5L20.0194 53H6L21.0689 32.5L6 12ZM26.9116 12L41.9806 32.5L26.9116 53H40.9311L56 32.5L40.9311 12H26.9116Z"
                  fill="#F7FA5A"/>
        </svg>
    ),
    arrowRight: (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.4248 16.6L12.8581 11.1667C13.4998 10.525 13.4998 9.47503 12.8581 8.83336L7.4248 3.40002"
                  stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    ),
    grid: (
        <svg width="207" height="203" viewBox="0 0 207 203" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M54.0858 181.824L54.0858 115.937H114.064L114.064 181.824H54.0858ZM53.0858 181.824L53.0858 115.937H0V114.937H53.0858L53.0858 48.3716H0V47.3716H53.0858L53.0858 0H54.0858L54.0858 47.3716H114.064V0H115.064V47.3716H175.698V0H176.698V47.3716H206.03V48.3716H176.698L176.698 114.937H206.03V115.937H176.698V181.824H206.03V182.824H176.698V203H175.698V182.824H115.064V203H114.064V182.824H54.0858V203H53.0858V182.824H0V181.824H53.0858ZM175.698 48.3716H115.064L115.064 114.937H175.698L175.698 48.3716ZM114.064 48.3716H54.0858L54.0858 114.937H114.064L114.064 48.3716ZM115.064 181.824L115.064 115.937H175.698V181.824H115.064Z"
                  fill="url(#paint0_linear_2001_794)" fillOpacity="0.3"/>
            <defs>
                <linearGradient id="paint0_linear_2001_794" x1="58.4264" y1="305.409" x2="47.0813" y2="92.0894"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C7C9E5"/>
                    <stop offset="1" stopColor="#C7C9E5" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    broken: (
        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M62.3569 14.7162C63.3926 13.3618 62.5162 10.5734 60.4448 11.3701C55.585 13.2822 50.5659 17.5046 47.2995 21.6474C43.8737 26.0292 43.2364 31.606 44.8298 36.8641C47.1401 44.5123 53.912 49.5314 60.4448 53.5945C63.4722 55.5066 66.181 50.7265 63.2332 48.8144C58.4531 45.8667 53.1153 42.1222 50.7252 36.8641C48.8929 32.7213 48.8929 28.0209 51.9203 24.3561C54.9477 20.771 59.4091 18.381 62.3569 14.7162Z"
                fill="#F7FA5A"/>
            <path
                d="M1.80526 48.8148C-1.22215 50.6472 1.56625 55.4273 4.59367 53.5949C11.1265 49.5318 17.8187 44.5127 20.2087 36.8645C21.8021 31.7657 21.2444 26.1889 17.978 21.9665C14.7913 17.744 9.61279 13.3622 4.67333 11.4502C2.60195 10.6535 1.64592 13.4419 2.76129 14.7963C5.62936 18.3814 9.93147 20.7714 12.9589 24.1972C16.1456 27.8619 16.305 32.6421 14.3929 37.0238C11.9232 42.1226 6.50571 45.8671 1.80526 48.8148Z"
                fill="#F7FA5A"/>
            <path
                d="M49.2882 1.25263C46.3404 6.03275 42.596 11.3706 37.3379 13.7606C33.1951 15.593 28.4946 15.593 24.8299 12.5656C21.1651 9.53817 18.7751 5.07673 15.1103 2.12899C13.8356 1.09329 11.0472 2.04932 11.8439 4.1207C13.7559 8.98049 17.9784 13.9996 22.1212 17.266C26.5029 20.6918 32.0797 21.3291 37.3379 19.7358C44.9861 17.4254 50.0052 10.6535 54.0683 4.1207C55.9007 1.09329 51.2002 -1.69511 49.2882 1.25263Z"
                fill="#F7FA5A"/>
            <path
                d="M54.0666 59.9689C50.0035 53.4361 44.9844 46.7439 37.3362 44.3539C32.2374 42.7605 26.6606 43.3182 22.4381 46.5846C18.2157 49.7713 13.8339 54.9498 11.9219 59.8892C11.1252 61.9606 13.9136 62.9167 15.268 61.8013C18.853 58.9332 21.2431 54.6311 24.6689 51.6037C28.3336 48.417 33.1137 48.2576 37.4955 50.1697C42.7537 52.5597 46.4184 57.8975 49.4458 62.6776C51.1985 65.7051 55.899 62.9963 54.0666 59.9689Z"
                fill="#F7FA5A"/>
        </svg>
    ),
    snapchat: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17.31 15.31C17.02 15.47 16.78 15.54 16.58 15.59C16.41 15.63 16.27 15.67 16.15 15.75C16.02 15.83 16.01 15.96 15.99 16.08C15.97 16.19 15.96 16.28 15.88 16.34C15.79 16.4 15.62 16.41 15.39 16.42C15.09 16.44 14.71 16.45 14.25 16.59C14.03 16.67 13.83 16.79 13.63 16.91C13.23 17.16 12.78 17.43 11.99 17.43C11.21 17.43 10.76 17.16 10.36 16.91C10.16 16.78 9.96001 16.66 9.73001 16.58C9.28001 16.44 8.89 16.42 8.59 16.41C8.36 16.4 8.20001 16.39 8.10001 16.33C8.02001 16.28 8.00999 16.18 7.98999 16.07C7.96999 15.95 7.95999 15.82 7.82999 15.74C7.70999 15.66 7.55999 15.62 7.39999 15.57C7.19999 15.52 6.96001 15.46 6.67001 15.3C6.33001 15.11 6.53001 15 6.64001 14.94C8.57001 14.01 8.87 12.57 8.88 12.46C8.88 12.45 8.88 12.45 8.88 12.44C8.9 12.32 8.90999 12.22 8.76999 12.09C8.67999 12 8.37 11.8 8.12 11.64C8.01 11.56 7.91001 11.5 7.85001 11.47C7.57001 11.27 7.45001 11.08 7.54001 10.83C7.60001 10.66 7.76001 10.6 7.92001 10.6C7.97001 10.6 8.03001 10.61 8.07001 10.62C8.25001 10.66 8.43 10.73 8.59 10.79C8.7 10.84 8.78999 10.87 8.85999 10.89C8.87999 10.9 8.91001 10.9 8.92001 10.9C9.02001 10.9 9.05001 10.85 9.04001 10.75C9.04001 10.72 9.04 10.69 9.03 10.66C9.01 10.31 8.97999 9.70001 9.01999 9.14001C9.09999 8.30001 9.37 7.87001 9.69 7.51001C9.85 7.33001 10.58 6.56 11.97 6.56C13.37 6.56 14.09 7.33 14.25 7.5C14.57 7.87 14.84 8.29001 14.92 9.14001C14.97 9.73001 14.93 10.36 14.9 10.71V10.74C14.89 10.85 14.92 10.89 15.02 10.89C15.04 10.89 15.06 10.89 15.08 10.88C15.15 10.86 15.24 10.83 15.35 10.79C15.5 10.73 15.68 10.66 15.87 10.62C15.92 10.6 15.97 10.6 16.02 10.6C16.19 10.6 16.34 10.67 16.4 10.84C16.49 11.08 16.37 11.27 16.09 11.47C16.04 11.51 15.94 11.58 15.82 11.64C15.58 11.8 15.26 12 15.17 12.09C15.03 12.22 15.04 12.32 15.06 12.44C15.06 12.45 15.06 12.46 15.06 12.46C15.08 12.57 15.38 14.01 17.3 14.94C17.45 15 17.65 15.12 17.31 15.31Z"
                  fill="white"/>
        </svg>
    ),
    youtube: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 4H7C4 4 2 6 2 9V15C2 18 4 20 7 20H17C20 20 22 18 22 15V9C22 6 20 4 17 4ZM13.89 13.03L11.42 14.51C10.42 15.11 9.59998 14.65 9.59998 13.48V10.51C9.59998 9.34001 10.42 8.88001 11.42 9.48001L13.89 10.96C14.84 11.54 14.84 12.46 13.89 13.03Z"
                fill="white"/>
        </svg>
    ),
    facebook: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 16.19C22 19.83 19.83 22 16.19 22H15C14.45 22 14 21.55 14 21V15.23C14 14.96 14.22 14.73 14.49 14.73L16.25 14.7C16.39 14.69 16.51 14.59 16.54 14.45L16.89 12.54C16.92 12.36 16.78 12.19 16.59 12.19L14.46 12.22C14.18 12.22 13.96 12 13.95 11.73L13.91 9.28C13.91 9.12 14.04 8.98001 14.21 8.98001L16.61 8.94C16.78 8.94 16.91 8.81001 16.91 8.64001L16.87 6.23999C16.87 6.06999 16.74 5.94 16.57 5.94L13.87 5.98001C12.21 6.01001 10.89 7.37 10.92 9.03L10.97 11.78C10.98 12.06 10.76 12.28 10.48 12.29L9.28 12.31C9.11 12.31 8.98001 12.44 8.98001 12.61L9.01001 14.51C9.01001 14.68 9.14 14.81 9.31 14.81L10.51 14.79C10.79 14.79 11.01 15.01 11.02 15.28L11.11 20.98C11.12 21.54 10.67 22 10.11 22H7.81C4.17 22 2 19.83 2 16.18V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81V16.19V16.19Z"
                fill="white"/>
        </svg>
    ),
    award: (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5891 19.5287L20.1722 15.1919C19.9162 15.5929 19.5268 15.8906 19.0727 16.0328L17.4245 16.5173L16.3036 17.8122C15.7815 18.4151 14.9553 18.6574 14.1903 18.4322L12.6633 17.9854L12.5369 17.9476L12.4107 17.9843L10.8836 18.4322C10.1186 18.6574 9.29246 18.4151 8.77015 17.8122L7.6504 16.5132L6.00013 16.0328C5.54641 15.8904 5.15757 15.5927 4.90163 15.1919L2.48485 19.5287C2.30758 19.8597 2.32529 20.2613 2.53071 20.5755C2.73632 20.8898 3.09702 21.0669 3.47132 21.0375L6.46335 20.7534L7.80008 23.446C7.97326 23.7786 8.31364 23.9905 8.68832 23.9995C9.06318 24.0082 9.41307 23.8127 9.6019 23.4889L12.5369 18.2113L15.4719 23.4878C15.6607 23.8118 16.0108 24.0073 16.3855 23.9984C16.7603 23.9894 17.1007 23.7775 17.2739 23.4451L18.6106 20.7523L21.6025 21.0363C21.9764 21.065 22.3362 20.8878 22.5414 20.574C22.7468 20.2603 22.7649 19.8597 22.5891 19.5287Z"
                fill="#93E156"/>
            <path
                d="M5.27837 12.2234L5.56544 14.221C5.62248 14.6156 5.90451 14.9407 6.28721 15.0525L8.22156 15.6216L9.54487 17.1487C9.80696 17.448 10.2193 17.5673 10.6007 17.4542L12.5349 16.8851L14.4692 17.4542C14.8506 17.5673 15.2629 17.448 15.5248 17.1487L16.8483 15.6216L18.7827 15.0525C19.1654 14.9407 19.4474 14.6156 19.5045 14.221L19.7915 12.2234L21.1148 10.6964C21.3754 10.3951 21.4364 9.96937 21.2707 9.60718L20.4328 7.7746L20.72 5.77724C20.7765 5.38299 20.5979 4.99191 20.2628 4.77661L18.5678 3.68314L17.7289 1.85075C17.5632 1.48856 17.2018 1.2563 16.8036 1.25611H14.7848L13.0877 0.161895C12.7522 -0.053965 12.3216 -0.053965 11.9863 0.161895L10.2892 1.25313H8.27133C7.87316 1.25331 7.51172 1.48558 7.346 1.84777L6.50717 3.68016L4.80899 4.77455C4.47402 4.99004 4.29544 5.38094 4.35192 5.77519L4.63899 7.77255L3.80127 9.60513C3.63556 9.96732 3.69633 10.3931 3.95692 10.6943L5.27837 12.2234ZM12.5369 4.51806C14.9969 4.5175 16.9915 6.51132 16.9919 8.97115C16.9922 11.4312 14.9982 13.4256 12.5384 13.4257C10.0784 13.4259 8.08399 11.4319 8.08399 8.9719C8.08623 6.51337 10.0786 4.52085 12.5369 4.51806Z"
                fill="#93E156"/>
        </svg>
    ),
    coins: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.7808 13.2813C16.633 12.8994 18.8327 10.4565 18.8327 7.49984C18.8327 4.27818 16.221 1.6665 12.9993 1.6665C10.0427 1.6665 7.59976 3.86623 7.21791 6.7184M13.8327 12.4998C13.8327 15.7215 11.221 18.3332 7.99935 18.3332C4.77769 18.3332 2.16602 15.7215 2.16602 12.4998C2.16602 9.27818 4.77769 6.6665 7.99935 6.6665C11.221 6.6665 13.8327 9.27818 13.8327 12.4998Z"
                stroke="#010302" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    lock: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6667 9.16667V6.66667C14.6667 4.36548 12.8012 2.5 10.5 2.5C8.19881 2.5 6.33333 4.36548 6.33333 6.66667V9.16667M7 17.5H14C15.4001 17.5 16.1002 17.5 16.635 17.2275C17.1054 16.9878 17.4878 16.6054 17.7275 16.135C18 15.6002 18 14.9001 18 13.5V13.1667C18 11.7665 18 11.0665 17.7275 10.5317C17.4878 10.0613 17.1054 9.67883 16.635 9.43915C16.1002 9.16667 15.4001 9.16667 14 9.16667H7C5.59987 9.16667 4.8998 9.16667 4.36502 9.43915C3.89462 9.67883 3.51217 10.0613 3.27248 10.5317C3 11.0665 3 11.7665 3 13.1667V13.5C3 14.9001 3 15.6002 3.27248 16.135C3.51217 16.6054 3.89462 16.9878 4.36502 17.2275C4.8998 17.5 5.59987 17.5 7 17.5Z"
                stroke="white" strokeOpacity="0.4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    clock: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.4993 4.99984V9.99984L13.8327 11.6665M18.8327 9.99984C18.8327 14.6022 15.1017 18.3332 10.4993 18.3332C5.89698 18.3332 2.16602 14.6022 2.16602 9.99984C2.16602 5.39746 5.89698 1.6665 10.4993 1.6665C15.1017 1.6665 18.8327 5.39746 18.8327 9.99984Z"
                stroke="#7D7E7D" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    infoCircle: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_299_402)">
                <path
                    d="M8.00065 10.6668V8.00016M8.00065 5.3335H8.00732M14.6673 8.00016C14.6673 11.6821 11.6826 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6826 1.3335 14.6673 4.31826 14.6673 8.00016Z"
                    stroke="white" strokeOpacity="0.4" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_299_402">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    eth: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#7B5FCB"/>
            <path d="M11.9666 4.7998L11.8711 5.12406V14.5324L11.9666 14.6276L16.3337 12.0462L11.9666 4.7998Z"
                  fill="#F9F9F9"/>
            <path d="M11.9669 4.7998L7.59961 12.0462L11.9669 14.6276V10.0611V4.7998Z" fill="#C0C0C0"/>
            <path d="M11.9659 15.4546L11.9121 15.5202V18.8716L11.9659 19.0287L16.3357 12.8745L11.9659 15.4546Z"
                  fill="#E0E0E0"/>
            <path d="M11.9669 19.0287V15.4546L7.59961 12.8745L11.9669 19.0287Z" fill="#C0C0C0"/>
            <path d="M11.9668 14.6276L16.334 12.0461L11.9668 10.061V14.6276Z" fill="#979797"/>
            <path d="M7.59961 12.0461L11.9669 14.6276V10.061L7.59961 12.0461Z" fill="#DFDFDF"/>
        </svg>
    ),
    chevronDown: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    number3_mobile: (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#FAEC5A"/>
            <path
                d="M19.5954 17.928V15.0795C20.7672 14.3598 22.0713 14 23.5077 14C25.3978 14 26.872 14.4998 27.9304 15.4993C29.0077 16.4788 29.5464 17.7181 29.5464 19.2174C29.5464 20.2969 29.2912 21.2464 28.7809 22.066C28.2895 22.8656 27.5524 23.4753 26.5696 23.8951C27.6469 24.1749 28.488 24.7046 29.0928 25.4843C29.6976 26.2639 30 27.2434 30 28.4228C30 30.042 29.4519 31.3813 28.3557 32.4408C27.2595 33.4803 25.7191 34 23.7345 34C21.8634 34 20.2852 33.6602 19 32.9805V30.1019C20.3986 30.9015 21.9296 31.3013 23.5928 31.3013C24.7079 31.3013 25.5773 31.0115 26.201 30.4318C26.8436 29.8321 27.1649 29.0725 27.1649 28.1529C27.1649 27.1934 26.8153 26.4538 26.116 25.934C25.4167 25.4143 24.3677 25.1544 22.9691 25.1544H21.8351V22.6357H22.9691C24.0086 22.6357 24.878 22.3658 25.5773 21.8261C26.2955 21.2664 26.6546 20.5067 26.6546 19.5472C26.6546 18.5877 26.3617 17.8681 25.7758 17.3883C25.2088 16.9085 24.4244 16.6687 23.4227 16.6687C22.1375 16.6687 20.8617 17.0885 19.5954 17.928Z"
                fill="#010302"/>
        </svg>
    ),
    number3_desktop: (
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="64" height="64" rx="32" fill="#FAEC5A"/>
            <path
                d="M23.5353 20.9012V16.9153C25.5369 15.6384 27.849 15 30.4718 15C33.8192 15 36.4075 15.8627 38.2365 17.5882C40.1 19.2792 41.0318 21.3843 41.0318 23.9035C41.0318 25.7325 40.5659 27.3718 39.6341 28.8212C38.7369 30.2706 37.391 31.3576 35.5965 32.0824C37.5635 32.531 39.1165 33.4455 40.2553 34.8259C41.4286 36.1718 42.0153 37.8627 42.0153 39.8988C42.0153 42.6596 41.049 44.9545 39.1165 46.7835C37.1839 48.6126 34.4576 49.5271 30.9376 49.5271C27.6937 49.5271 24.8812 48.9576 22.5 47.8188V43.7294C25.0537 45.0753 27.8318 45.7482 30.8341 45.7482C33.0427 45.7482 34.751 45.1788 35.9588 44.04C37.2012 42.9012 37.8224 41.4173 37.8224 39.5882C37.8224 37.7592 37.1322 36.3616 35.7518 35.3953C34.4059 34.3945 32.4561 33.8941 29.9024 33.8941H27.7282V30.3224H29.9024C31.8349 30.3224 33.4569 29.7875 34.7682 28.7176C36.1141 27.6133 36.7871 26.1467 36.7871 24.3176C36.7871 22.4886 36.2176 21.1082 35.0788 20.1765C33.94 19.2447 32.3698 18.7788 30.3682 18.7788C27.9871 18.7788 25.7094 19.4863 23.5353 20.9012Z"
                fill="#010302"/>
        </svg>
    ),
    metamask: (
        <svg width="58" height="63" viewBox="0 0 58 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.0951 5.72754L31.7617 21.4775L35.3369 12.1853L51.0951 5.72754Z" fill="#E2761B" stroke="#E2761B"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M4.95781 5.72754L25.6011 21.6909L21.9409 12.2113L4.95781 5.72754ZM45.5124 42.3833L39.9698 51.0712L51.8287 54.4094L55.2379 42.5759L45.5124 42.3833ZM2.76172 42.5759L6.14998 54.4094L18.0089 51.0712L12.4664 42.3833L2.76172 42.5759Z"
                fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M18.3936 27.6584L15.1895 32.8865L26.6069 33.4334L26.2013 20.199L18.3936 27.6584ZM40.965 27.6584L33.0559 20.0459L32.7923 33.4334L44.1895 32.8865L40.965 27.6584ZM19.0426 51.5459L25.8971 47.9365L19.9755 42.949L19.0426 51.5459ZM33.4615 47.9365L40.3363 51.5459L39.3832 42.949L33.4615 47.9365Z"
                fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M38.6673 51.6912L32.4254 48.6816L32.9226 52.7126L32.8673 54.4089L38.6673 51.6912ZM19.334 51.6912L25.134 54.4089L25.0972 52.7126L25.5575 48.6816L19.334 51.6912Z"
                fill="#D7C1B3" stroke="#D7C1B3" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M25.9126 40.0914L20.7148 38.0424L24.3827 35.7959L25.9126 40.0914ZM33.4511 40.0914L34.9809 35.7959L38.6672 38.0424L33.4511 40.0914Z"
                fill="#233447" stroke="#233447" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M19.1357 51.5458L20.1007 42.6863L13.8086 42.8827L19.1357 51.5458ZM39.2784 42.6863L40.2433 51.5458L45.5705 42.8827L39.2784 42.6863ZM44.0628 32.9321L32.7652 33.4777L33.8106 39.784L35.4791 35.9871L39.4996 37.9729L44.0628 32.9321ZM19.8996 37.9729L23.9201 35.9871L25.5685 39.784L26.634 33.4777L15.3163 32.9321L19.8996 37.9729Z"
                fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M15.1895 32.9316L19.9755 42.9872L19.8132 37.9813L15.1895 32.9316ZM39.586 37.9813L39.3832 42.9872L44.1895 32.9316L39.586 37.9813ZM26.6069 33.4781L25.5321 39.7956L26.8706 47.2498L27.1748 37.4348L26.6069 33.4781ZM32.7923 33.4781L32.2447 37.4129L32.4881 47.2498L33.8468 39.7956L32.7923 33.4781Z"
                fill="#E4751F" stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M33.4564 40.4795L32.2228 47.9582L33.1066 48.6819L38.4831 43.6815L38.6672 38.6592L33.4564 40.4795ZM20.7148 38.6592L20.8621 43.6815L26.2387 48.6819L27.1225 47.9582L25.9072 40.4795L20.7148 38.6592Z"
                fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M32.8673 55.029L32.9226 52.855L32.4622 52.3407H25.5206L25.0972 52.855L25.134 55.029L19.334 51.5459L21.3594 53.6498L25.4654 57.2732H32.5175L36.6419 53.6498L38.6673 51.5459L32.8673 55.029Z"
                fill="#C0AD9E" stroke="#C0AD9E" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M32.6167 47.9941L31.681 47.25H26.2806L25.3448 47.9941L24.8574 52.9773L25.3058 52.4812H32.6557L33.1431 52.9773L32.6167 47.9941Z"
                fill="#161616" stroke="#161616" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M53.4887 21.9617L55.2379 13.5455L52.6244 5.72754L32.8275 20.4559L40.4417 26.9124L51.2045 30.0684L53.5916 27.2837L52.5627 26.5411L54.209 25.0352L52.9331 24.0451L54.5794 22.7868L53.4887 21.9617ZM2.76172 13.5455L4.51093 21.9617L3.39967 22.7868L5.04598 24.0451L3.79066 25.0352L5.43698 26.5411L4.40803 27.2837L6.7746 30.0684L17.5374 26.9124L25.1516 20.4559L5.35466 5.72754L2.76172 13.5455Z"
                fill="#763D16" stroke="#763D16" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M51.5568 30.8567L40.6182 27.4718L43.9437 32.7593L38.9868 42.936L45.5124 42.8475H55.2379L51.5568 30.8567ZM17.3396 27.4718L6.40096 30.8567L2.76172 42.8475H12.4664L18.971 42.936L14.035 32.7593L17.3396 27.4718ZM32.1894 33.3124L32.8796 20.5473L36.0587 11.4546H21.9409L25.0782 20.5473L25.8103 33.3124L26.0612 37.3388L26.0821 47.25H31.8757L31.9175 37.3388L32.1894 33.3124Z"
                fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    threeVerticalLines: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#010302"/>
            <path d="M8 8L8 16" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M12 8L12 16" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M16 8L16 16" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    ),
    resetBlock: (
        <svg width="209" height="130" viewBox="0 0 209 130" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_390_3417)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M71.8028 0.829269C71.0015 -0.276422 68.9985 -0.276423 68.1972 0.829268L63.001 8H20C8.95431 8 0 16.9543 0 28V110C0 121.046 8.9543 130 20 130H189C200.046 130 209 121.046 209 110V28C209 16.9543 200.046 8 189 8H76.999L71.8028 0.829269Z"
                      fill="#1D1E1E" fillOpacity="1"/>
            </g>
            <defs>
                <filter id="filter0_b_390_3417" x="-32" y="-32" width="273" height="194"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="16"/>
                    <feComposite in2="SourceAlpha" operator="in"
                                 result="effect1_backgroundBlur_390_3417"/>
                    <feBlend mode="normal" in="SourceGraphic"
                             in2="effect1_backgroundBlur_390_3417" result="shape"/>
                </filter>
            </defs>
        </svg>
    ),
    moneyBag: (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_390_3775)">
                <path
                    d="M38.1951 16.6516C37.9476 16.395 37.6065 16.25 37.2501 16.25H26.7501C26.3937 16.25 26.0526 16.395 25.8051 16.6516C23.8862 18.6387 7.0625 36.2893 7.0625 43.8125C7.0625 54.6695 18.2503 63.5 32 63.5C45.7498 63.5 56.9376 54.6695 56.9376 43.8125C56.9376 36.2893 40.1139 18.6387 38.1951 16.6516Z"
                    fill="#C3CB65"/>
                <path
                    d="M48.7341 2.25613C46.5474 -0.216625 40.8591 3.02788 37.5935 5.225C36.0973 3.09875 33.8818 0.5 31.9997 0.5C30.1176 0.5 27.9021 3.09875 26.4059 5.225C23.1482 3.02529 17.4493 -0.219209 15.2653 2.25613C14.6599 2.95417 14.3744 3.8737 14.4778 4.79188C14.9005 9.65333 24.7967 17.6885 25.9228 18.5863C26.1572 18.7744 26.4491 18.8763 26.7497 18.8751H37.2496C37.5472 18.8753 37.8359 18.7744 38.0686 18.589C39.1948 17.6887 49.0936 9.66404 49.5136 4.79458C49.6211 3.87653 49.3383 2.95552 48.7341 2.25613Z"
                    fill="#C3CB65"/>
                <path
                    d="M42.5 18.875H21.5C20.7752 18.875 20.1875 18.2873 20.1875 17.5624C20.1875 16.8375 20.7752 16.25 21.5 16.25H42.5001C43.225 16.25 43.8126 16.8377 43.8126 17.5625C43.8126 18.2874 43.2248 18.875 42.5 18.875Z"
                    fill="#C3CB65"/>
                <path
                    d="M32.0008 53.0001C28.8797 53.3083 26.0368 51.1909 25.4383 48.1123C25.3774 47.3874 25.9157 46.7504 26.6406 46.6895C27.3655 46.6286 28.0025 47.1669 28.0634 47.8918C28.1789 49.262 29.9455 50.375 32.0009 50.375C34.1376 50.375 35.9384 49.1727 35.9384 47.75C35.9384 46.469 34.6941 45.65 32.0245 45.1513L31.7384 45.0987C27.478 44.3112 25.4384 42.5709 25.4384 39.875C25.849 36.6399 28.7545 34.3155 32.0009 34.625C35.122 34.3167 37.9648 36.4341 38.5633 39.5128C38.6242 40.2376 38.0859 40.8746 37.361 40.9356C36.6362 40.9965 35.9992 40.4581 35.9383 39.7333C35.828 38.363 34.0614 37.2501 32.0008 37.2501C29.864 37.2501 28.0633 38.4523 28.0633 39.875C28.0633 41.1561 29.3101 41.9751 31.9824 42.4738L32.2712 42.5289C36.5237 43.3164 38.5712 45.0542 38.5712 47.7526C38.1591 50.9901 35.2492 53.3142 32.0008 53.0001Z"
                    fill="black"/>
                <path
                    d="M32 55.625C31.2752 55.625 30.6875 55.0373 30.6875 54.3125V33.3125C30.6875 32.5877 31.2752 32 32 32C32.7249 32 33.3126 32.5877 33.3126 33.3125V54.3126C33.3126 55.0373 32.7249 55.625 32 55.625Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_390_3775">
                    <rect width="63" height="63" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    ),
    dashboardInterface: (
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_390_4033)">
                <path
                    d="M45.4271 0H3.57292C1.60271 0 0 1.60271 0 3.57292V8.67708C0 10.6473 1.60271 12.25 3.57292 12.25H45.4271C47.3973 12.25 49 10.6473 49 8.67708V3.57292C49 1.60271 47.3973 0 45.4271 0Z"
                    fill="#6270ED"/>
                <path
                    d="M3.57292 49H18.8854C20.8556 49 22.4583 47.3973 22.4583 45.4271V19.9063C22.4583 17.936 20.8556 16.3333 18.8854 16.3333H3.57292C1.60271 16.3333 0 17.936 0 19.9063V45.4271C0 47.3973 1.60271 49 3.57292 49Z"
                    fill="#6270ED"/>
                <path
                    d="M45.4284 16.3335H30.1159C28.1457 16.3335 26.543 17.9362 26.543 19.9064V27.0522C26.543 29.0225 28.1457 30.6252 30.1159 30.6252H45.4284C47.3986 30.6252 49.0013 29.0225 49.0013 27.0522V19.9064C49.0013 17.9362 47.3986 16.3335 45.4284 16.3335Z"
                    fill="#6270ED"/>
                <path
                    d="M45.4284 34.7085H30.1159C28.1457 34.7085 26.543 36.3112 26.543 38.2814V45.4272C26.543 47.3975 28.1457 49.0002 30.1159 49.0002H45.4284C47.3986 49.0002 49.0013 47.3975 49.0013 45.4272V38.2814C49.0013 36.3112 47.3986 34.7085 45.4284 34.7085Z"
                    fill="#6270ED"/>
            </g>
            <defs>
                <clipPath id="clip0_390_4033">
                    <rect width="49" height="49" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    ),
    check: (
        <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.6361 1.21078L7.21462 13.2831L1.72722 6.27332" stroke="white" strokeWidth="2.26612"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    waveYellow: (
        <svg width="1297" height="131" viewBox="0 0 1297 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 94V94C27.5324 94 53.9372 83.0628 73.4056 63.5944L92.9826 44.0174C121.166 15.8335 159.392 0 199.25 0V0C239.108 0 277.334 15.8335 305.517 44.0174L324.433 62.933C344.892 83.3921 369.614 99.0835 396.836 108.888V108.888C445.921 126.567 500.02 124.012 547.221 101.787L556.982 97.1906C569.96 91.0794 582.283 83.6629 593.759 75.0557L614.554 59.4591C653.638 30.1468 704.068 20.5292 751.196 33.4002V33.4002C780.135 41.3037 806.231 57.2698 826.443 79.4375L830.211 83.5704C838.051 92.1693 846.599 100.151 856.292 106.59C880.989 122.997 909.226 135.18 954 129.5C956.08 129.236 958.141 128.904 960.18 128.508C1046.52 111.746 1106.8 14.7516 1193.29 30.7615C1209.23 33.7126 1225.91 38.4486 1243 45.5C1325.4 79.5 1387 56.3333 1407.5 40.5"
                stroke="#FAEC5A"
                strokeWidth={4}
            />
        </svg>
    ),
    waveBack: (
        <svg width="1297" height="131" viewBox="0 0 1297 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 94V94C27.5324 94 53.9372 83.0628 73.4056 63.5944L92.9826 44.0174C121.166 15.8335 159.392 0 199.25 0V0C239.108 0 277.334 15.8335 305.517 44.0174L324.433 62.933C344.892 83.3921 369.614 99.0835 396.836 108.888V108.888C445.921 126.567 500.02 124.012 547.221 101.787L556.982 97.1906C569.96 91.0794 582.283 83.6629 593.759 75.0557L614.554 59.4591C653.638 30.1468 704.068 20.5292 751.196 33.4002V33.4002C780.135 41.3037 806.231 57.2698 826.443 79.4375L830.211 83.5704C838.051 92.1693 846.599 100.151 856.292 106.59C880.989 122.997 909.226 135.18 954 129.5C956.08 129.236 958.141 128.904 960.18 128.508C1046.52 111.746 1106.8 14.7516 1193.29 30.7615C1209.23 33.7126 1225.91 38.4486 1243 45.5C1325.4 79.5 1387 56.3333 1407.5 40.5"
                stroke="#010302"
                strokeWidth={6}
            />
        </svg>
    ),
    waveDashed: (
        <svg width="1297" height="131" viewBox="0 0 1297 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 94V94C27.5324 94 53.9372 83.0628 73.4056 63.5944L92.9826 44.0174C121.166 15.8335 159.392 0 199.25 0V0C239.108 0 277.334 15.8335 305.517 44.0174L324.433 62.933C344.892 83.3921 369.614 99.0835 396.836 108.888V108.888C445.921 126.567 500.02 124.012 547.221 101.787L556.982 97.1906C569.96 91.0794 582.283 83.6629 593.759 75.0557L614.554 59.4591C653.638 30.1468 704.068 20.5292 751.196 33.4002V33.4002C780.135 41.3037 806.231 57.2698 826.443 79.4375L830.211 83.5704C838.051 92.1693 846.599 100.151 856.292 106.59C880.989 122.997 909.226 135.18 954 129.5C956.08 129.236 958.141 128.904 960.18 128.508C1046.52 111.746 1106.8 14.7516 1193.29 30.7615C1209.23 33.7126 1225.91 38.4486 1243 45.5C1325.4 79.5 1387 56.3333 1407.5 40.5"
                stroke="#FAEC5A"
                strokeDasharray="8 8"
            />
        </svg>
    ),
    backgroundFigure_MadeByTraders: (
        <svg width="933" height="799" viewBox="0 0 933 799" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_2080_806)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M527.61 219.951C486.03 216.883 447.61 204.616 405.857 205.526C338.858 206.984 244.338 182.283 210.141 226.864C176.157 271.168 234.706 331.396 280.758 368.615C319.227 399.704 419.547 368.393 436.096 409.476C459.816 468.36 332.172 528.007 367.273 583.387C391.874 622.2 482.649 579.539 527.61 553.862C566.671 531.554 571.657 489.054 592.094 454.991C601.418 439.449 608.917 424.168 615.27 407.755C620.292 394.781 615.53 379.732 625.529 368.615C656.505 334.176 729.743 318.816 732.932 276.938C735.309 245.724 674.705 233.117 636.651 222.555C601.831 212.89 564.437 222.668 527.61 219.951Z"
                      fill="url(#paint0_linear_2080_806)"/>
            </g>
            <defs>
                <filter id="filter0_f_2080_806" x="0" y="0" width="933" height="799" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_2080_806"/>
                </filter>
                <linearGradient id="paint0_linear_2080_806" x1="466.5" y1="200" x2="466.5" y2="599"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#797B7A"/>
                    <stop offset="1" stopColor="#DDE1DF"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    backgroundFigure_WhoWeAre: (
        <svg width="548" height="786" viewBox="0 0 548 786" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_2080_808)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M58.3672 258.765C84.4289 261.11 104.167 235.445 129.478 228.807C204.821 209.048 349.267 103.451 354.179 181.187C360.084 274.624 132.999 258.449 137.261 351.975C141.806 451.694 417.008 443.842 370.167 531.992C323.736 619.369 198.372 425.359 99.4495 423.131C62.2197 422.293 85.1939 499.144 58.3672 524.972C18.5783 563.279 -52.3566 645.389 -85.5466 601.241C-127.111 545.953 13.1152 456.484 -32.3254 404.336C-93.6896 333.914 -261.803 435.928 -302.75 351.975C-335.473 284.881 -170.608 291.1 -103.422 258.565C-74.8872 244.747 -52.2401 215.272 -20.5353 215.312C10.0107 215.349 27.944 256.028 58.3672 258.765Z"
                      fill="url(#paint0_linear_2080_808)"/>
            </g>
            <defs>
                <filter id="filter0_f_2080_808" x="-479" y="-19" width="1026.53" height="804.941"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="86" result="effect1_foregroundBlur_2080_808"/>
                </filter>
                <linearGradient id="paint0_linear_2080_808" x1="34.2654" y1="153" x2="34.2654" y2="613.941"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#797B7A"/>
                    <stop offset="1" stopColor="#DDE1DF"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    backgroundFigure_WeNeverRunOut: (
        <svg width="993" height="849" viewBox="0 0 993 849" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_392_29755)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M564.49 222.451C518.228 218.998 475.484 205.195 429.031 206.218C354.489 207.86 249.329 180.063 211.283 230.23C173.473 280.087 238.613 347.862 289.849 389.744C332.648 424.729 444.261 389.495 462.673 435.726C489.063 501.989 347.051 569.111 386.103 631.43C413.473 675.107 514.467 627.101 564.49 598.205C607.947 573.102 613.494 525.276 636.232 486.944C646.606 469.456 654.949 452.259 662.017 433.789C667.604 419.189 662.306 402.255 673.431 389.744C707.894 350.99 789.377 333.706 792.925 286.579C795.569 251.454 728.143 237.267 685.804 225.381C647.065 214.506 605.462 225.509 564.49 222.451Z"
                      fill="url(#paint0_linear_392_29755)"/>
            </g>
            <defs>
                <filter id="filter0_f_392_29755" x="0" y="0" width="993" height="849" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_392_29755"/>
                </filter>
                <linearGradient id="paint0_linear_392_29755" x1="496.5" y1="200" x2="496.5" y2="649"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#797B7A"/>
                    <stop offset="1" stopColor="#DDE1DF"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    backgroundFigure_FixToken: (
        <svg width="1097" height="745" viewBox="0 0 1097 745" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_2082_819)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M521.692 263.998C492.958 266.037 471.195 243.714 443.289 237.94C360.218 220.753 200.958 128.901 195.542 196.518C189.032 277.792 439.406 263.723 434.707 345.074C429.696 431.813 126.269 424.984 177.915 501.659C229.107 577.663 367.329 408.906 476.396 406.969C517.445 406.24 492.114 473.087 521.692 495.553C565.562 528.874 643.772 600.295 680.366 561.894C726.193 513.803 571.585 435.98 621.686 390.62C689.344 329.364 874.699 418.1 919.845 345.074C955.925 286.715 774.15 292.124 700.075 263.824C668.613 251.805 643.643 226.167 608.687 226.201C575.008 226.233 555.236 261.617 521.692 263.998Z"
                      fill="url(#paint0_linear_2082_819)"/>
            </g>
            <defs>
                <filter id="filter0_f_2082_819" x="0" y="0" width="1096.53" height="744.941"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="86" result="effect1_foregroundBlur_2082_819"/>
                </filter>
                <linearGradient id="paint0_linear_2082_819" x1="548.266" y1="172" x2="548.266" y2="572.941"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#797B7A"/>
                    <stop offset="1" stopColor="#DDE1DF"/>
                </linearGradient>
            </defs>
        </svg>
    ),
    send: (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.7143 3.15271C1.59331 2.7882 1.53282 2.60595 1.57685 2.49369C1.61509 2.39619 1.69692 2.32234 1.79782 2.29426C1.91399 2.26194 2.08911 2.34074 2.43934 2.49834L13.5842 7.5135C13.9271 7.6678 14.0985 7.74495 14.1515 7.85212C14.1976 7.94524 14.1976 8.05449 14.1515 8.1476C14.0985 8.25477 13.9271 8.33192 13.5842 8.48622L2.44322 13.4997C2.09193 13.6577 1.91629 13.7368 1.8 13.7043C1.69901 13.6761 1.61717 13.6021 1.57906 13.5044C1.53518 13.3919 1.59632 13.2093 1.71861 12.844L3.27523 8.19419C3.29617 8.13164 3.30664 8.10037 3.31082 8.06837C3.31453 8.03997 3.31457 8.01121 3.31093 7.9828C3.30683 7.95079 3.29644 7.91948 3.27566 7.85688L1.7143 3.15271Z"
                fill="white"/>
        </svg>
    ),
    whatsNextWaveGrey: (
        <svg width="1312" height="302" viewBox="0 0 1312 302" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 301H3.68121C24.8641 301 44.6748 290.519 56.5921 273.006L103.42 204.192C132.666 161.215 196.185 161.711 224.757 205.14L268.86 272.176C280.697 290.168 300.79 301 322.326 301H328
                M328 301H331.681C352.864 301 372.675 290.519 384.592 273.006L431.42 204.192C460.666 161.215 524.185 161.711 552.757 205.14L596.86 272.176C608.697 290.168 628.79 301 650.326 301H656
                M656 301H659.681C680.864 301 700.675 290.519 712.592 273.006L759.42 204.192C788.666 161.215 852.185 161.711 880.757 205.14L924.86 272.176C936.697 290.168 956.79 301 978.326 301H984
                M984 301H987.681C1008.86 301 1028.67 290.519 1040.59 273.006L1087.42 204.192C1116.67 161.215 1180.19 161.711 1208.76 205.14L1252.86 272.176C1264.7 290.168 1284.79 301 1306.33 301H1312"
                stroke="white" strokeOpacity={0.1}/>
        </svg>
    ),
    whatsNextWaveWhite: (
        <svg width="1312" height="302" viewBox="0 0 1312 302" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 301H3.68121C24.8641 301 44.6748 290.519 56.5921 273.006L103.42 204.192C132.666 161.215 196.185 161.711 224.757 205.14L268.86 272.176C280.697 290.168 300.79 301 322.326 301H328
                M328 301H331.681C352.864 301 372.675 290.519 384.592 273.006L431.42 204.192C460.666 161.215 524.185 161.711 552.757 205.14L596.86 272.176C608.697 290.168 628.79 301 650.326 301H656
                M656 301H659.681C680.864 301 700.675 290.519 712.592 273.006L759.42 204.192C788.666 161.215 852.185 161.711 880.757 205.14L924.86 272.176C936.697 290.168 956.79 301 978.326 301H984
                M984 301H987.681C1008.86 301 1028.67 290.519 1040.59 273.006L1087.42 204.192C1116.67 161.215 1180.19 161.711 1208.76 205.14L1252.86 272.176C1264.7 290.168 1284.79 301 1306.33 301H1312"
                stroke="white" strokeWidth={3}/>
        </svg>
    ),
}