import style from "./SelectField.module.scss"
import {svgIcons} from "../../../assets/svgIcons";
import {useRef, useState} from "react";
import {clsx} from "clsx";
import {useOutsideButNotOnTargetClick} from "../../../hooks/useOutsideButNotOnTargetClick";

interface IListItem {
    short: string
    full: string
}

const list = [
    {
        short: "ETH 1",
        full: "ERC-20 1"
    },
    {
        short: "ETH 2",
        full: "ERC-20 2"
    },
    {
        short: "ETH 3",
        full: "ERC-20 3"
    },
    {
        short: "ETH 4",
        full: "ERC-20 4"
    },
    {
        short: "ETH 5",
        full: "ERC-20 5"
    },
]


export const SelectField = () => {
    const [value,setValue] = useState(list[0]);
    const [open, setOpen] = useState(false);

    const listRef = useRef<HTMLDivElement>(null!);
    const btnRef = useRef<HTMLButtonElement>(null!);

    useOutsideButNotOnTargetClick(listRef, btnRef, () => setOpen(false))

    return (
        <div className={style.selectField}>

            <button className={clsx({
                [style.btn]: true,
                [style.btn_open]: open,
            })}
                    onClick={() => setOpen(!open)}
                    ref={btnRef}
            >
                {svgIcons.eth}
                <p>
                    <span>{value.short}</span> ({value.full})
                </p>

                {svgIcons.chevronDown}
            </button>

            {
                open && (
                    <div className={style.list}
                         ref={listRef}
                    >
                        {
                            list.map((item, key) => (
                                <button key={key}
                                        className={style.item}
                                        onClick={() => {
                                            setValue(item)
                                            setOpen(false)
                                        }}
                                >
                                    {svgIcons.eth}
                                    <p>
                                        <span>{item.short}</span> ({item.full})
                                    </p>
                                </button>
                            ))
                        }
                    </div>
                )
            }

        </div>
    )
}