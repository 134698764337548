import style from "./JoinTheFix.module.scss"
import back from "../../assets/png/WeFund/background.png";
import {svgIcons} from "../../assets/svgIcons";
import {Link} from "react-router-dom";
import {Card} from "./Card/Card";
import {Lines} from "../_common/Lines/Lines";

export const JoinTheFix = () => {

    return (
        <div className={style.joinTheFix}>

            <div className={style.linesWrapper}>
                <Lines count={4}/>
            </div>

            <div className={style.linesWrapper}>
                <Lines count={3}/>
            </div>

            <div className={style.linesWrapper}>
                <Lines count={3}/>
            </div>

            <img src={back} alt="" className={style.back}/>

            <div className={style.inner}>

                <div className={style.label}>
                    <p>
                        Join Us, Start Trading <span>&</span> Prove Your Skills 🤟
                    </p>
                </div>

                <h1 className={style.title}>
                    <p>
                        Join the $FIX
                    </p>
                    <p>
                        Token {svgIcons.growthChart} Presale
                    </p>
                </h1>

                <div className={style.content}>

                    <div className={style.contentTop}>

                        <Card/>

                        <Link to="/"
                              className={style.viewLeaderboard}
                        >
                            {svgIcons.award}
                            <p>View Leaderboard</p>
                        </Link>


                    </div>

                    <div className={style.contentBottom}>

                        <div className={style.descriptionTop}>
                            <p>
                                <span>First come, first serve</span> the <span>fastest contributors, get</span> the
                                highest <span>$FIX bonus</span> on top of their purchase
                            </p>
                        </div>

                        <div className={style.groups}>
                            {
                                [
                                    {
                                        fix: "+30%",
                                        percent: "First 20%",
                                    },
                                    {
                                        fix: "+20%",
                                        percent: "21% - 30%",
                                    },
                                    {
                                        fix: "+15%",
                                        percent: "31% - 40%",
                                    },
                                    {
                                        fix: "+10%",
                                        percent: "41% - 50%",
                                    },
                                    {
                                        fix: "+5%",
                                        percent: "51% - 100%",
                                    },
                                ].map(({fix, percent}, key) => (
                                    <div key={key}
                                         className={style.group}
                                    >

                                        <div className={style.left}>
                                            <p>
                                                Group {key + 1}
                                            </p>
                                            <p>
                                                <span>$FIX </span><span>{fix}</span>
                                            </p>
                                        </div>

                                        <div className={style.right}>
                                            <p>
                                                // {percent}
                                            </p>
                                            <p>Pre-Sale transactions</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <p className={style.descriptionBottom}>
                            If in total 10,000 transactions contributed to $FIX pre-sale and you are amongst the first
                            2,000 (20%), then you get 30% bonus. If for example you are the 4123th transaction you get
                            10% bonus etc
                        </p>

                    </div>

                </div>

            </div>


        </div>
    )
}