import style from "./HavingTrouble.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom} from "../_common/ButtonCustom/ButtonCustom";

export const HavingTrouble = () => {
    return (
        <div className={style.havingTrouble}>
            <div className={style.inner}>

                <p className={style.label}>
                    support@fundfix.trade 🤟
                </p>

                <h2 className={style.title}>
                    <p>
                        Having {svgIcons.broken} trouble
                    </p>
                    <p>
                        buying the presale?
                    </p>
                </h2>

                <p className={style.description}>
                    Need support? Our team will call you and help right away
                </p>

                <ButtonCustom label="Contact Us"
                              className={style.contactUsBtn}
                />

            </div>
        </div>
    )
}