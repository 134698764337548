import style from "./YourPresaleDashboard.module.scss"
import {svgIcons} from "../../assets/svgIcons";
import {ButtonCustom, VariantEnum} from "../_common/ButtonCustom/ButtonCustom";
import src from "../../assets/png/YourPresaleDashboard/src.png";

export const YourPresaleDashboard = () => {
    return (
        <div className={style.yourPresaleDashboard}>
            <div className={style.inner}>

                <h2 className={style.title}>
                    <p>
                        Your {svgIcons.dashboardInterface} Presale
                    </p>
                    <p>
                        Dashboard
                    </p>
                </h2>

                <p className={style.description}>
                    Manage your presale participation from a single dashboard. View your current investments, monitor
                    live token sales, and check your transaction history. The dashboard provides a seamless experience
                    for buying and tracking $FIX tokens
                </p>

                <ButtonCustom label="Access the Presale Dashboard"
                              variant={VariantEnum.outlined}
                              className={style.btn}
                />

                <div className={style.content}>

                    <div className={style.top}>

                        <div className={style.card}>
                            <img src={src} alt=""/>
                        </div>
                    </div>

                    <div className={style.bottom}>
                        <p className={style.bottomTitle}>
                            Dashboard Features
                        </p>
                        <div className={style.bottomItems}>
                            {
                                [
                                    {
                                        icon: svgIcons.coin,
                                        title: "Live Token Sale Updates:",
                                        text: "View the current status of the presale",
                                    },
                                    {
                                        icon: svgIcons.star,
                                        title: "Investment Tracking:",
                                        text: "See your investments and remaining vesting schedules",
                                    },
                                    {
                                        icon: svgIcons.searchNormal,
                                        title: "Secure Transactions:",
                                        text: "All purchases are protected with advanced encryption",
                                    },
                                ].map(({icon, title, text}, key) => (
                                    <div key={key}
                                         className={style.bottomItem}
                                    >
                                        {icon}
                                        <p>
                                            <span>{title}</span> <span>{text}</span>

                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}